import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { QuickOrderService } from '../../services/quick-order.service';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  DEFAULT_SCOPE,
  GlobalMessageService,
  GlobalMessageType,
  OrderEntry,
  Product,
  ProductService,
  TranslationService
} from '@spartacus/core';
import { JagaActiveCartService } from '../../../jaga-cart/facade/jaga-active-cart.service';
import { QuickOrderEntry } from '../../models/quick-order.model';
import { QuickOrderActions } from '../actions';
import {
  ADD_QUICK_ORDER_ENTRY,
  ADD_QUICK_ORDER_ENTRY_FAIL,
  ADD_QUICK_ORDER_TO_CART,
  ADD_QUICK_ORDER_TO_CART_FAIL,
  ADD_QUICK_ORDER_TO_CART_SUCCESS,
  QuickOrderAddEntry,
  QuickOrderAddEntryFail,
  QuickOrderAddToCart,
  QuickOrderAddToCartFail,
  QuickOrderAddToCartSuccess
} from '../actions/quick-order-entries.action';

@Injectable({
  providedIn: 'root'
})
export class QuickOrderEntriesEffects {

  
  addEntry$: Observable<QuickOrderActions.QuickOrderAddEntrySuccess | QuickOrderActions.QuickOrderAddEntryFail> = createEffect(() =>
    this.actions$.pipe(
      ofType(ADD_QUICK_ORDER_ENTRY),
      switchMap((action: QuickOrderAddEntry) => {
        return this.productService.hasError(action.code, DEFAULT_SCOPE).pipe(
          mergeMap((hasError) => {
            if (!hasError) {
              return this.productService.get(action.code).pipe(
                filter(Boolean),
                map((product: Product) => {
                  if (product.code != null) {
                    const entry: QuickOrderEntry = {
                      product,
                      quantity: 1,
                      basePrice: product.price,
                    };
                    return new QuickOrderActions.QuickOrderAddEntrySuccess(entry);
                  }
                })
              );
            } else {
              return this.translationService.translate('quickOrder.noProduct', { code: action.code }).pipe(
                switchMap((errorMessage: string) => {
                  return of(new QuickOrderActions.QuickOrderAddEntryFail(errorMessage));
                })
              );
            }
          })
        );
      })
    ));

  
  addToCart$: Observable<QuickOrderActions.QuickOrderAddToCartSuccess | QuickOrderActions.QuickOrderAddToCartFail> = createEffect(() =>
    this.actions$.pipe(
      ofType(ADD_QUICK_ORDER_TO_CART),
      mergeMap((action: QuickOrderAddToCart) => {
        if (action.entries?.length >= action.minEntries) {
          const cartEntries: OrderEntry[] = this.quickOrderService.getCartEntries(action.entries);
          this.activeCartService.addEntries(cartEntries);
          return this.translationService.translate('quickOrder.successMessage').pipe(
            switchMap((message) => {
              return of(new QuickOrderAddToCartSuccess(message, action.entries));
            })
          );
        } else {
          return this.translationService.translate('quickOrder.minValidRows', { minRows: action.minEntries }).pipe(
            switchMap((message) => {
              return of(new QuickOrderAddToCartFail(message));
            })
          );
        }
      })
    ));

  
  addQuickOrderSuccess$: Observable<QuickOrderActions.ClearQuickOrderEntries> = createEffect(() => this.actions$.pipe(
    ofType(ADD_QUICK_ORDER_TO_CART_SUCCESS),
    tap((action: QuickOrderAddToCartSuccess) => {
        this.globalMessageService.add(action.successMessage, GlobalMessageType.MSG_TYPE_CONFIRMATION);
      }
    ),
    map(() => new QuickOrderActions.ClearQuickOrderEntries())
  ));

  
  addEntryFailMessage$ = createEffect(() => this.actions$.pipe(
    ofType(ADD_QUICK_ORDER_ENTRY_FAIL),
    tap((action: QuickOrderAddEntryFail) => {
      this.globalMessageService.add(action.errorMessage, GlobalMessageType.MSG_TYPE_ERROR);
    })
  ), { dispatch: false });

  
  addToCartFailMessage$ = createEffect(() => this.actions$.pipe(
    ofType(ADD_QUICK_ORDER_TO_CART_FAIL),
    tap((action: QuickOrderAddToCartFail) => {
      this.globalMessageService.add(action.errorMessage, GlobalMessageType.MSG_TYPE_ERROR);
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private quickOrderService: QuickOrderService,
    private productService: ProductService,
    private translationService: TranslationService,
    private activeCartService: JagaActiveCartService,
    private globalMessageService: GlobalMessageService
  ) {
  }
}
