import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  PageLayoutService,
  ProductListComponentService,
  ViewConfig,
  ViewModes,
} from '@spartacus/storefront';
import { take, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ProductSearchPage, RoutingService } from '@spartacus/core';

@Component({
  selector: 'jaga-product-list',
  templateUrl: './jaga-product-list.component.html',
})
export class JagaProductListComponent implements OnInit, OnDestroy {
  isInfiniteScroll: boolean;
  model$: Observable<ProductSearchPage> =
    this.productListComponentService.model$.pipe(
      tap((model) => {
        if (!model.products?.length) {
          this.routingService.go({ cxRoute: 'searchEmpty' });
        }
      })
    );
  viewMode$ = new BehaviorSubject<ViewModes>(ViewModes.Grid);
  ViewModes = ViewModes;
  private subscription = new Subscription();

  constructor(
    private pageLayoutService: PageLayoutService,
    private productListComponentService: ProductListComponentService,
    public scrollConfig: ViewConfig,
    protected routingService: RoutingService
  ) {}

  ngOnInit() {
    this.isInfiniteScroll = this.scrollConfig.view.infiniteScroll.active;

    this.subscription.add(
      this.pageLayoutService.templateName$
        .pipe(take(1))
        .subscribe((template) => {
          this.viewMode$.next(
            template === 'ProductGridPageTemplate' ||
              template === 'SearchResultsGridPageTemplate'
              ? ViewModes.Grid
              : ViewModes.List
          );
        })
    );
  }

  sortList(sortCode: string): void {
    this.productListComponentService.sort(sortCode);
  }

  setViewMode(mode: ViewModes): void {
    this.viewMode$.next(mode);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  doPaginationYourself($event: number) {}
}
