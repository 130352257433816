import { Component } from '@angular/core';

@Component({
  selector: 'jaga-contact',
  templateUrl: './jaga-contact.component.html'
})
export class JagaContactComponent {

  constructor() { }

}
