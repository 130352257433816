import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '@spartacus/core';
import { JagaProductBaseItemComponent } from '../jaga-product-base-item/jaga-product-base-item.component';

@Component({
  selector: 'jaga-product-grid-item',
  templateUrl: './jaga-product-grid-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JagaProductGridItemComponent extends JagaProductBaseItemComponent {
  constructor(protected authService: AuthService) {
    super(authService);
  }
}
