import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { Product, ProductScope, ProductService, RoutingService, VariantMatrixElement } from '@spartacus/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'jaga-product-variant-generic-selector',
  templateUrl: './jaga-product-variant-generic-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JagaProductVariantGenericSelectorComponent implements OnChanges, OnDestroy {
  @Input()
  product: Product;
  variants: any[] = [];
  private subscription = new Subscription();

  constructor(
    private productService: ProductService,
    private routingService: RoutingService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.product) {
      this.setVariants();
    }
  }

  changeVariant(code: string): void {
    if (code) {
      this.subscription.add(
        this.productService
          .get(code, ProductScope.VARIANTS)
          .pipe(filter(Boolean), take(1))
          .subscribe((product: Product) => {
            this.routingService.go({ cxRoute: 'product', params: product });
          })
      );
    }
  }

  variantHasImages(variants: VariantMatrixElement[]): boolean {
    return variants.some(
      (variant: VariantMatrixElement) => variant.parentVariantCategory?.hasImage
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private setVariants(): void {
    this.variants = [];

    const levels = Array.from(
      { length: this.product.categories.length },
      (_, k) => k + 1
    );

    let productMatrix = JSON.parse(JSON.stringify(this.product.variantMatrix));

    levels.forEach((level) => {
      const currentLevelProductVariantIndex = productMatrix.findIndex(
        (variant: VariantMatrixElement) => variant.variantOption.code === this.product.code
      );

      if (1 !== level) {
        productMatrix = productMatrix[currentLevelProductVariantIndex]?.elements;
      }

      this.variants.push(productMatrix);
    });
  }
}
