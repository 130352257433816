import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { CmsService, CMSTabParagraphContainer } from '@spartacus/core';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { CmsComponentData, ComponentWrapperDirective } from '@spartacus/storefront';

@Component({
  selector: 'jaga-tab-paragraph-container',
  templateUrl: './jaga-tab-paragraph-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JagaTabParagraphContainerComponent implements AfterViewInit, OnDestroy {
  activeTabs: number[] = [];

  @ViewChildren(ComponentWrapperDirective)
  children!: QueryList<ComponentWrapperDirective>;

  tabTitleParams: Observable<any>[] = [];

  subscription: Subscription;
  components$: Observable<any[]> = this.componentData.data$.pipe(
    distinctUntilChanged((x, y) => x?.components === y?.components),
    switchMap((data) =>
      combineLatest(
        (data?.components ?? '').split(' ').map((component) =>
          this.cmsService.getComponentData<any>(component).pipe(
            distinctUntilChanged(),
            map((tab) => {
              if (!tab) {
                return undefined;
              }

              if (!tab.flexType) {
                tab = {
                  ...tab,
                  flexType: tab.typeCode,
                };
              }

              return {
                ...tab,
                title: `${data.uid}.tabs.${tab.uid}`,
              };
            })
          )
        )
      )
    )
  );

  constructor(
    public componentData: CmsComponentData<CMSTabParagraphContainer>,
    protected cmsService: CmsService
  ) {
  }

  select(tabNum: number): void {
    if (this.activeTabs.includes(tabNum)) {
      this.activeTabs.splice(this.activeTabs.indexOf(tabNum), 1);
    } else {
      this.activeTabs.push(tabNum);
    }
  }

  ngAfterViewInit(): void {
    // If the sub cms components data exist, the components created before ngAfterViewInit are called.
    // In this case, the title parameters are directly pulled from them.
    // If the sub cms components data does not exist, it should should be loaded first.
    // In this case, listen to the changes to wait for them to be created.
    if (this.children.length > 0) {
      this.getTitleParams(this.children);
    } else {
      this.subscription = this.children.changes.subscribe(
        (tabComps: QueryList<ComponentWrapperDirective>) =>
          this.getTitleParams(tabComps)
      );
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getTitleParams(children: QueryList<ComponentWrapperDirective>) {
    children.forEach((comp) => {
      if (comp.cmpRef && comp.cmpRef.instance.tabTitleParam$) {
        this.tabTitleParams.push(comp.cmpRef.instance.tabTitleParam$);
      } else {
        this.tabTitleParams.push(null);
      }
    });
  }

}
