import { Component } from '@angular/core';
import { UpdatePasswordComponent } from '@spartacus/user/profile/components';
import { RoutingService } from '@spartacus/core';
import { JagaUpdatePasswordComponentService } from './jaga-update-password-component.service';

@Component({
  selector: 'jaga-update-password-form',
  templateUrl: './jaga-update-password-form.component.html',
  providers: [JagaUpdatePasswordComponentService],
})
export class JagaUpdatePasswordFormComponent extends UpdatePasswordComponent {
  constructor(
    protected updatePasswordComponentService: JagaUpdatePasswordComponentService,
    protected routingService: RoutingService
  ) {
    super(updatePasswordComponentService);
  }

  onCancel() {
    this.routingService.go({ cxRoute: 'home' });
  }
}
