<h4>{{ 'orderCost.orderSummary' | cxTranslate }}</h4>

<div *ngIf="cart" class="cx-summary-partials">

  <div *ngIf="cart.totalDiscounts?.value > 0"
       class="cx-summary-row jaga-discount">
    <div class="col-8 cx-summary-label">
      {{ 'orderCost.discount' | cxTranslate }}
    </div>

    <div class="col-4 cx-summary-amount jaga-discount">
      - {{ cart.totalDiscounts?.formattedValue }}
    </div>
  </div>

  <div class="cx-summary-row">
    <div class="col-8 cx-summary-label">
      {{ 'orderCost.subtotal' | cxTranslate }}
    </div>
    <div class="col-4 cx-summary-amount">
      {{ cart.subTotal?.formattedValue }}
    </div>
  </div>
  <div class="cx-summary-row">
    <div class="col-8 cx-summary-label">
      {{
      (cart.deliveryCost?.formattedValue
          ? 'orderCost.shipping'
          : 'orderCost.estimatedShipping'
      ) | cxTranslate
      }}
    </div>
    <div class="col-4 cx-summary-amount">
      {{
      cart.deliveryCost?.formattedValue
        ? cart.deliveryCost.formattedValue
        : '€0,00'
      }}
    </div>
  </div>
  <div *ngIf="cart.net" class="cx-summary-row">
    <div class="col-8 cx-summary-label">
      {{ 'orderCost.salesTax' | cxTranslate }}
    </div>
    <div class="col-4 cx-summary-amount">
      {{ cart.totalTax?.formattedValue }}
    </div>
  </div>
  <div class="cx-summary-row cx-summary-total">
    <div class="col-8 cx-summary-label">
      {{ 'orderCost.total' | cxTranslate }}
    </div>
    <div class="col-4 cx-summary-amount">
      {{ cart.totalPriceWithTax?.formattedValue }}
    </div>
  </div>

  <div *ngIf="!cart.net" class="cx-summary-row">
    {{ 'orderCost.grossTax' | cxTranslate }}
    {{ cart.totalTax?.formattedValue }}.'
  </div>
</div>

<cx-applied-coupons
  *ngIf="showAppliedCoupons"
  [isReadOnly]="true"
  [vouchers]="cart.appliedVouchers">
</cx-applied-coupons>
