import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { JagaProduct } from '../../../models/product.model';
import { JagaCurrentProductService } from '../../../jaga-current-product.service';

@Component({
  selector: 'jaga-product-connection-tab',
  templateUrl: './jaga-product-connection-tab.component.html'
})
export class JagaProductConnectionTabComponent {

  product$: Observable<JagaProduct> = this.currentProductService.getProduct();

  constructor(private currentProductService: JagaCurrentProductService) {
  }

}
