import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  CartCouponModule,
  CartSharedModule,
  ItemCounterModule,
  MediaModule,
  ModalModule,
  PromotionsModule,
} from '@spartacus/storefront';
import { ReactiveFormsModule } from '@angular/forms';
import { FeaturesConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JagaCartItemComponent } from './jaga-cart-item/jaga-cart-item.component';
import { JagaCartItemListComponent } from './jaga-cart-item-list/jaga-cart-item-list.component';
import { JagaOrderSummaryComponent } from './jaga-order-summary/jaga-order-summary.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CartCouponModule,
    ReactiveFormsModule,
    UrlModule,
    NgbModule,
    PromotionsModule,
    I18nModule,
    MediaModule,
    ItemCounterModule,
    FeaturesConfigModule,
    CartSharedModule,
    ModalModule,
  ],
  declarations: [
    JagaCartItemComponent,
    JagaCartItemListComponent,
    JagaOrderSummaryComponent,
  ],
  exports: [
    JagaCartItemComponent,
    JagaCartItemListComponent,
    JagaOrderSummaryComponent,
  ],
})
export class JagaCartSharedModule {}
