import { NgModule } from '@angular/core';
import { JagaBadRequestHandler } from './http-interceptors/handlers/jaga-bad-request/jaga-bad-request.handler';
import {
  BadRequestHandler,
  CmsConfig,
  ConfigModule,
  I18nModule,
  PRODUCT_NORMALIZER,
  RoutingConfig,
} from '@spartacus/core';
import { CommonModule } from '@angular/common';
import { JagaTogglePasswordComponent } from './components/jaga-toggle-password/jaga-toggle-password.component';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { JagaPasswordRequirementsComponent } from './components/jaga-password-requirements/jaga-password-requirements.component';
import { JagaImageComponent } from './components/jaga-image/jaga-image.component';
import { JagaFixAnchorPipe } from './pipes/jaga-fix-anchor.pipe';
import { JagaSafePipe } from './pipes/jaga-safe.pipe';
import { JagaLineSeparatorComponent } from './components/jaga-line-separator/jaga-line-separator.component';
import { HideNoContentDirective } from './directives/hide-no-content.directive';
import { CheckNoContentDirective } from './directives/check-no-content.directive';
import { ProductNameNormalizer } from './normalizer/product-name-normalizer';
import { JagaMediaZoomComponent } from './components/jaga-media-zoom/jaga-media-zoom.component';
import { PinchZoomModule } from '@olafvv/ngx-pinch-zoom';

@NgModule({
  declarations: [
    JagaTogglePasswordComponent,
    JagaPasswordRequirementsComponent,
    JagaImageComponent,
    JagaFixAnchorPipe,
    JagaSafePipe,
    JagaLineSeparatorComponent,
    HideNoContentDirective,
    CheckNoContentDirective,
    JagaMediaZoomComponent,
  ],
  providers: [
    {
      provide: BadRequestHandler,
      useExisting: JagaBadRequestHandler,
      multi: false,
    },
    // {
    //   provide: HttpErrorInterceptors,
    //   useExisting: JagaHttpErrorInterceptor,
    //   multi: false,
    // },
    {
      provide: PRODUCT_NORMALIZER,
      useExisting: ProductNameNormalizer,
      multi: true,
    },
  ],
  exports: [
    JagaTogglePasswordComponent,
    JagaFixAnchorPipe,
    JagaSafePipe,
    JagaPasswordRequirementsComponent,
    HideNoContentDirective,
    CheckNoContentDirective,
    JagaMediaZoomComponent,
  ],
  imports: [
    ConfigModule.withConfig({
      cmsComponents: {
        CMSImageComponent: {
          component: JagaImageComponent,
        },
        LineSeparator: {
          component: JagaLineSeparatorComponent,
        },
      },
      routing: {
        routes: {
          product: {
            paths: ['product/:productCode/:seoName'],
            paramsMapping: { productCode: 'code' },
          },
        },
      },
    } as CmsConfig | RoutingConfig),
    CommonModule,
    I18nModule,
    IconModule,
    MediaModule,
    PinchZoomModule,
  ],
})
export class JagaCommonModule {}
