import { Component, OnInit } from '@angular/core';
import {
  AddressBookComponent,
  AddressBookComponentService,
} from '@spartacus/storefront';
import { TranslationService } from '@spartacus/core';
import { Address } from '@spartacus/core/src/model/address.model';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { JagaAddress } from '../../../jaga-checkout/core/models/jaga-Address.model';
import { JagaAddressType } from '../../../jaga-checkout/core/models/jaga-AddressType.model';

@Component({
  selector: 'jaga-address-book',
  templateUrl: './jaga-address-book.component.html',
})
export class JagaAddressBookComponent
  extends AddressBookComponent
  implements OnInit
{
  customerAddresses$: Observable<Address[]>;

  constructor(
    service: AddressBookComponentService,
    translation: TranslationService
  ) {
    super(service, translation);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.customerAddresses$ = this.addresses$.pipe(
      map((addresses) =>
        addresses.filter(
          (address: JagaAddress) =>
            address.addressType === JagaAddressType.Company
        )
      )
    );
  }

  getCardContent(address: Address) {
    return combineLatest([
      this.translation.translate('addressCard.default'),
    ]).pipe(
      map(([defaultText]) => {
        let region = '';
        if (address.region && address.region.name) {
          region = `${address.region.name}, `;
        }

        let name = '';
        if (address.firstName && address.lastName) {
          name = `${address.firstName} ${address.lastName}`;
        }

        return {
          textBold: '',
          text: [
            name,
            address.companyName,
            address.line1,
            `${address.postalCode} ${address.town}`,
            region + address.country.name,
            address.phone,
          ],
          actions: [],
          header: address.defaultAddress ? `✓ ${defaultText}` : '',
          deleteMsg: '',
        };
      })
    );
  }
}
