import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JagaWebsiteSelectorComponent } from './components/jaga-website-selector/jaga-website-selector.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';


@NgModule({
  declarations: [JagaWebsiteSelectorComponent],
  imports: [
    IconModule,
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        JagaWebsiteSelectorComponent: {
          component: JagaWebsiteSelectorComponent,
        }
      }
    } as CmsConfig)
  ]
})
export class JagaWebsiteModule {
}
