import { QuickOrderSearchState } from '../quick-order-state';
import { Suggestion } from '@spartacus/core';
import { QuickOrderActions } from '../actions';

export const initialState: QuickOrderSearchState = {
  suggestions: [],
};

export function reducer(
  state = initialState,
  action: QuickOrderActions.QuickOrderSearchAction
): QuickOrderSearchState {
  switch (action.type) {
    case QuickOrderActions.GET_QUICK_ORDER_SUGGESTIONS_SUCCESS: {
      const suggestions: Suggestion[] = action.payload;

      return {
        suggestions,
      };
    }

    case QuickOrderActions.CLEAR_QUICK_ORDER_SEARCH_RESULT: {
      return {
        suggestions: [],
      };
    }
  }
  return state;
}

export const getQuickOrderSuggestions = (state: QuickOrderSearchState): Suggestion[] => state.suggestions;
