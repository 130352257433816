import { filter, take, tap } from 'rxjs/operators';
import { PaymentTypes } from '../models/mollie-checkout';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ActiveCartService,
  Order,
  StateWithProcess,
  UserIdService,
} from '@spartacus/core';
import { MollieCheckoutActions } from '../store/actions';
import { StateWithCheckoutAndPaymentType } from '../store/mollie-checkout-state';
import { CheckoutService } from '@spartacus/checkout/core';

@Injectable({
  providedIn: 'root',
})
export class MollieCheckoutService extends CheckoutService {
  constructor(
    checkoutStore: Store<StateWithCheckoutAndPaymentType>,
    protected processStateStore: Store<StateWithProcess<void>>,
    activeCartService: ActiveCartService,
    userIdService: UserIdService
  ) {
    super(checkoutStore, processStateStore, activeCartService, userIdService);
  }

  /**
   * Check whether the selected payment type is 'CARD'.
   * If it is, start Mollie 'create order' flow, otherwise use the default Spartacus 'place order' flow.
   */
  placeOrder() {
    this.activeCartService
      .getActive()
      .pipe(filter(Boolean), take(1))
      .subscribe((cart: any) => {
        if (cart.paymentType?.code === PaymentTypes.CARD_PAYMENT) {
          if (this.actionAllowed()) {
            this.userIdService
              .getUserId()
              .pipe(
                tap((userId) => {
                  this.checkoutStore.dispatch(
                    new MollieCheckoutActions.MollieCreateOrder({
                      userId,
                      cartId: cart.code,
                      termsChecked: true,
                    })
                  );
                })
              )
              .subscribe();
          }
        } else {
          super.placeOrder(true);
        }
      });
  }

  confirmPlaceOrder(order: Order) {
    this.checkoutStore.dispatch(
      new MollieCheckoutActions.MollieConfirmPlaceOrder(order)
    );
  }
}
