import { Component } from '@angular/core';
import {
  OrderDetailsService,
  OrderDetailTotalsComponent,
} from '@spartacus/order/components';

@Component({
  selector: 'jaga-order-detail-totals',
  templateUrl: './jaga-order-detail-totals.component.html',
})
export class JagaOrderDetailTotalsComponent extends OrderDetailTotalsComponent {
  constructor(orderDetailsService: OrderDetailsService) {
    super(orderDetailsService);
  }
}
