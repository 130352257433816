import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JagaMiniCartComponent } from './components/jaga-mini-cart/jaga-mini-cart.component';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import { RouterModule } from '@angular/router';
import {
  CarouselModule,
  CartCouponModule,
  CartSharedModule,
  FormErrorsModule,
  IconModule,
  ItemCounterModule,
  MediaModule,
  PromotionsModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { JagaCartTotalsComponent } from './components/jaga-cart-totals/jaga-cart-totals.component';
import { jagaOccCartConfig } from './config/jaga-occ-cart.config';
import { JagaCartDetailsComponent } from './components/jaga-cart-details/jaga-cart-details.component';
import { JagaCartSharedModule } from './jaga-cart-shared';
import { JagaAddToCartComponent } from './components/jaga-add-to-cart/jaga-add-to-cart.component';
import { ReactiveFormsModule } from '@angular/forms';
import { JagaAddedToCartDialogCarouselComponent } from './components/jaga-add-to-cart/jaga-added-to-cart-dialog-carousel/jaga-added-to-cart-dialog-carousel.component';
import { JagaAddedToCartDialogComponent } from './components/jaga-add-to-cart/jaga-added-to-cart-dialog/jaga-added-to-cart-dialog.component';
import { JagaCartCouponComponent } from './components/jaga-cart-coupon/jaga-cart-coupon.component';

@NgModule({
  declarations: [
    JagaMiniCartComponent,
    JagaCartTotalsComponent,
    JagaCartDetailsComponent,
    JagaAddToCartComponent,
    JagaAddedToCartDialogComponent,
    JagaAddedToCartDialogCarouselComponent,
    JagaCartCouponComponent,
  ],
  exports: [JagaAddToCartComponent, JagaAddedToCartDialogComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        MiniCartComponent: {
          component: JagaMiniCartComponent,
        },
        CartTotalsComponent: {
          component: JagaCartTotalsComponent,
        },
        CartComponent: {
          component: JagaCartDetailsComponent,
          guards: [AuthGuard],
        },
        ProductAddToCartComponent: {
          component: JagaAddToCartComponent,
        },
        CartApplyCouponComponent: {
          component: JagaCartCouponComponent,
        },
      },
    } as CmsConfig),
    ConfigModule.withConfig(jagaOccCartConfig),
    I18nModule,
    UrlModule,
    RouterModule,
    IconModule,
    PromotionsModule,
    JagaCartSharedModule,
    CartSharedModule,
    ItemCounterModule,
    ReactiveFormsModule,
    SpinnerModule,
    CarouselModule,
    MediaModule,
    CartCouponModule,
    FormErrorsModule,
  ],
})
export class JagaCartModule {}
