<div *ngIf="order$ | async as order" class="cx-order-items">
  <h4 class="cx-order-items-header">
    {{ 'checkoutOrderConfirmation.orderItems' | cxTranslate }}
  </h4>

  <jaga-cart-item-list
    [items]="order.entries"
    [readonly]="true"
    [promotionLocation]="promotionLocation"
  ></jaga-cart-item-list>
</div>
