<ng-container *ngIf="component.data$ | async as data">
  <cx-generic-link
    *ngIf="data.urlLink && data.urlLink.length; else jagaBannerTemplate"
    [url]="data.urlLink"
    [target]="getTarget(data)"
    [title]="data.media?.altText"
  >
    <ng-container *ngTemplateOutlet="jagaBannerTemplate">
    </ng-container>
  </cx-generic-link>

  <ng-template #jagaBannerTemplate>
    <p *ngIf="data.headline" [innerHTML]="data.headline"></p>
    <cx-media [container]="data.media"></cx-media>
    <p *ngIf="data.content" [innerHTML]="data.content"></p>
  </ng-template>
</ng-container>
