<section *ngIf="!(loading$ | async); else loading" class="container p-0 mt-4">
  <form (ngSubmit)="submitForm()" [formGroup]="registerForm">

    <div class="row justify-content-between">
      <div class="col-md-5">
        <div class="cx-section">

          <h3 class="h3">{{ 'register.jaga-companyInfo' | cxTranslate }}</h3>

          <div class="form-group jaga-radio-group">
            <label>
              <span class="label-content required">
                {{ 'register.jaga-existingCustomer.label' | cxTranslate }}
              </span>
            </label>

            <div class="form-check">
              <input [formControlName]="existingCustomerFormName"
                     class="form-check-input"
                     id="existingCustomerYes"
                     type="radio"
                     value="yes">
              <label class="form-check-label" for="existingCustomerYes">
                {{ 'register.jaga-existingCustomer.yes' | cxTranslate }}
              </label>
            </div>

            <div class="form-check">
              <input [formControlName]="existingCustomerFormName"
                     class="form-check-input"
                     id="existingCustomerNo"
                     type="radio"
                     value="no">
              <label class="form-check-label" for="existingCustomerNo">
                {{ 'register.jaga-existingCustomer.no' | cxTranslate }}
              </label>
            </div>

          </div>

          <div [formGroupName]="vatNumberCustomerNumberFormGroupName" class="form-group">
            <label>
              <span class="label-content required">
                {{ 'register.jaga-vatNumber.label' | cxTranslate }}
              </span>
              <input
                (blur)="setVatNumberValidator()"
                (focus)="vatNumberFormControl.clearAsyncValidators()"
                [formControlName]="vatNumberFormName"
                class="form-control"
                name="vatnumber"
                placeholder="{{ 'register.jaga-vatNumber.placeholder' | cxTranslate }}"
                type="text"/>
              <cx-form-errors [control]="vatNumberFormControl">
              </cx-form-errors>
            </label>
          </div>

          <div *ngIf="existingCustomer" [formGroupName]="vatNumberCustomerNumberFormGroupName" class="form-group">
            <label>
              <span class="label-content required">
                {{ 'register.jaga-customerNumber.label' | cxTranslate }}
              </span>
              <input
                [formControlName]="customerNumberFormName"
                class="form-control"
                name="customernumber"
                placeholder="{{ 'register.jaga-customerNumber.placeholder' | cxTranslate }}"
                type="text"/>
              <cx-form-errors [control]="customerNumberFormControl">
              </cx-form-errors>
            </label>
          </div>

          <ng-container *ngIf="!existingCustomer">
            <div class="form-group">
              <label>
              <span class="label-content required">
                {{ 'register.jaga-companyName.label' | cxTranslate }}
              </span>
                <input
                  [formControlName]="companyNameFormName"
                  class="form-control"
                  name="companyname"
                  placeholder="{{ 'register.jaga-companyName.placeholder' | cxTranslate }}"
                  type="text"/>
                <cx-form-errors [control]="companyNameFormControl">
                </cx-form-errors>
              </label>
            </div>

            <div class="form-group">
              <label>
                <span class="label-content required">
                  {{'register.jaga-billingEmailAddress.label' | cxTranslate}}
                </span>
                <input
                  [formControlName]="billingEmailFormName"
                  class="form-control"
                  name="email"
                  placeholder="{{'register.jaga-billingEmailAddress.placeholder' | cxTranslate}}"
                  type="email"/>
                <cx-form-errors
                  [control]="billingEmailFormControl">
                </cx-form-errors>
              </label>
            </div>

            <div class="form-group">
              <label>
                <span
                  class="label-content required">
                  {{'register.jaga-orderConfirmationEmailAddress.label' | cxTranslate}}
                </span>
                <input
                  [formControlName]="orderConfirmationEmailFormName"
                  class="form-control"
                  name="email"
                  placeholder="{{'register.jaga-orderConfirmationEmailAddress.placeholder' | cxTranslate}}"
                  type="email"/>
                <cx-form-errors
                  [control]="orderConfirmationEmailFormControl">
                </cx-form-errors>
              </label>
            </div>

            <!-- ADDRESS INFO -->
            <div class="form-group">
              <label>
                <span class="label-content required">
                  {{ 'register.jaga-country.label' | cxTranslate }}
                </span>
                <div class="select-wrapper">
                  <select [value]="countryFormControl.value" class="form-control" disabled>
                    <option
                      *ngFor="let country of countries"
                      [value]="country.isocode">{{ country.name }}</option>
                  </select>
                  <cx-icon [type]="ICON_TYPE.CARET_DOWN"></cx-icon>
                </div>
                <input [formControlName]="countryFormName" readonly type="hidden"/>
                <cx-form-errors [control]="countryFormControl">
                </cx-form-errors>
              </label>
            </div>

            <div class="form-group">
              <label>
              <span class="label-content required">
                {{ 'register.jaga-addressLine1.label' | cxTranslate }}
              </span>
                <input
                  [formControlName]="addressLine1FormName"
                  class="form-control"
                  name="addressline1"
                  placeholder="{{ 'register.jaga-addressLine1.placeholder' | cxTranslate }}"
                  type="text"/>
                <cx-form-errors [control]="addressLine1FormControl">
                </cx-form-errors>
              </label>
            </div>

            <div class="form-group">
              <label>
              <span class="label-content required">
                {{ 'register.jaga-postalCode.label' | cxTranslate }}
              </span>
                <input
                  [formControlName]="postalCodeFormName"
                  class="form-control"
                  name="postalcode"
                  placeholder="{{ 'register.jaga-postalCode.placeholder' | cxTranslate }}"
                  type="text"/>
                <cx-form-errors [control]="postalCodeFormControl">
                </cx-form-errors>
              </label>
            </div>

            <div class="form-group">
              <label>
              <span class="label-content required">
                {{ 'register.jaga-city.label' | cxTranslate }}
              </span>
                <input
                  [formControlName]="cityFormName"
                  class="form-control"
                  name="city"
                  placeholder="{{ 'register.jaga-city.placeholder' | cxTranslate }}"
                  type="text"/>
                <cx-form-errors [control]="cityFormControl">
                </cx-form-errors>
              </label>
            </div>

            <div class="form-group">
              <label>
                <span class="label-content required">
                  {{ 'register.jaga-region.label' | cxTranslate }}
                </span>
                <div class="select-wrapper">
                  <select [formControlName]="regionFormName" class="form-control">
                    <option disabled selected value="">
                      {{ 'register.jaga-region.placeholder' | cxTranslate }}
                    </option>
                    <option
                      *ngFor="let region of regions$ | async"
                      [value]="region.isocode">{{ region.name }}</option>
                  </select>
                  <cx-icon [type]="ICON_TYPE.CARET_DOWN"></cx-icon>
                </div>

                <cx-form-errors [control]="regionFormControl">
                </cx-form-errors>
              </label>
            </div>

            <!-- END ADDRESS INFO -->

            <div class="form-group">
              <label>
              <span class="label-content required">
                {{ 'register.jaga-numberOfInstallations.label' | cxTranslate }}
              </span>
                <input
                  [formControlName]="numberOfInstallationsFormName"
                  class="form-control"
                  name="numberofinstallationsperyear"
                  placeholder="{{ 'register.jaga-numberOfInstallations.placeholder' | cxTranslate }}"
                  type="number"/>
                <cx-form-errors [control]="numberOfInstallationsFormControl">
                </cx-form-errors>
              </label>
            </div>

            <div class="form-group">
              <label>
                <span class="label-content required">
                  {{ 'register.jaga-areaOfExpertise.label' | cxTranslate }}
                </span>
                <div class="select-wrapper">
                  <select [formControlName]="areaOfExpertiseFormName" class="form-control">
                    <option disabled selected value="">
                      {{ 'register.jaga-areaOfExpertise.placeholder' | cxTranslate }}
                    </option>
                    <option *ngFor="let areaOfExpertise of areaOfExpertiseOptions"
                            [value]="areaOfExpertise">
                      {{ 'register.jaga-areaOfExpertise.' + AreaOfExpertise[areaOfExpertise] | cxTranslate }}
                    </option>
                  </select>
                  <cx-icon [type]="ICON_TYPE.CARET_DOWN"></cx-icon>
                </div>
                <cx-form-errors [control]="areaOfExpertiseFormControl">
                </cx-form-errors>
              </label>
            </div>

            <div class="form-group">
              <label>
              <span class="label-content required">
                {{ 'register.jaga-numberOfEmployees.label' | cxTranslate }}
              </span>
                <div class="select-wrapper">
                  <select [formControlName]="numberOfEmployeesFormName" class="form-control">
                    <option disabled selected value="">
                      {{ 'register.jaga-numberOfEmployees.placeholder' | cxTranslate }}
                    </option>
                    <option *ngFor="let numberOfEmployees of numberOfEmployeesOptions"
                            [value]="numberOfEmployees">
                      {{ NumberOfEmployees[numberOfEmployees] }}
                    </option>
                  </select>
                  <cx-icon [type]="ICON_TYPE.CARET_DOWN"></cx-icon>
                </div>
                <cx-form-errors [control]="numberOfEmployeesFormControl">
                </cx-form-errors>
              </label>
            </div>

          </ng-container>

        </div>
      </div>

      <div class="col-md-5">
        <div class="cx-section">

          <h3 class="h3">{{ 'register.jaga-personalInfo' | cxTranslate }}</h3>

          <div class="form-group">
            <label>
              <span class="label-content required">
                {{'register.title' | cxTranslate}}
              </span>
              <div class="select-wrapper">
                <select [formControlName]="titleFormName" class="form-control">
                  <option disabled selected value="">
                    {{'register.selectTitle' | cxTranslate}}
                  </option>
                  <option
                    *ngFor="let title of titles$ | async"
                    [value]="title.code">{{ title.name }}</option>
                </select>
                <cx-icon [type]="ICON_TYPE.CARET_DOWN"></cx-icon>
              </div>
              <cx-form-errors [control]="titleFormControl">
              </cx-form-errors>
            </label>
          </div>

          <div class="form-group">
            <label>
              <span class="label-content required">
                {{'register.firstName.label' | cxTranslate}}
              </span>
              <input
                [formControlName]="firstNameFormName"
                class="form-control"
                name="firstname"
                placeholder="{{'register.firstName.placeholder' | cxTranslate}}"
                type="text"/>
              <cx-form-errors [control]="firstNameFormControl">
              </cx-form-errors>
            </label>
          </div>

          <div class="form-group">
            <label>
              <span class="label-content required">
                {{'register.lastName.label' | cxTranslate}}
              </span>
              <input
                [formControlName]="lastNameFormName"
                class="form-control"
                name="lastname"
                placeholder="{{'register.lastName.placeholder' | cxTranslate}}"
                type="text"/>
              <cx-form-errors [control]="lastNameFormControl">
              </cx-form-errors>
            </label>
          </div>

          <div class="form-group">
            <label>
              <span class="label-content required">
                {{'register.jaga-phoneNumber.label' | cxTranslate}}
              </span>
              <input
                [formControlName]="phoneNumberFormName"
                class="form-control"
                maxlength="30"
                name="phoneNumber"
                placeholder="{{'register.jaga-phoneNumber.placeholder' | cxTranslate}}"
                type="text"/>
              <cx-form-errors [control]="phoneNumberFormControl">
              </cx-form-errors>
            </label>
          </div>

          <div class="form-group">
            <label>
              <span class="label-content required">
                {{'register.emailAddress.label' | cxTranslate}}
              </span>
              <input
                [formControlName]="emailFormName"
                class="form-control"
                name="email"
                placeholder="{{'register.emailAddress.placeholder' | cxTranslate}}"
                type="email"/>
              <cx-form-errors
                [control]="emailFormControl">
              </cx-form-errors>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-6 mt-4">
        <div class="form-group">
          <div class="form-check">
            <label>
              <input
                [formControlName]="termsAndConditionsFormName"
                name="termsandconditions"
                type="checkbox"
              />
              <span class="form-check-label">
                  {{ 'register.confirmThatRead' | cxTranslate }}
                <a
                  [routerLink]="{ cxRoute: 'termsAndConditions' } | cxUrl"
                  target="_blank">
                    {{ 'register.termsAndConditions' | cxTranslate }}
                  </a>
                </span>
              <cx-form-errors [control]="termsAndConditionsFormControl">
              </cx-form-errors>
            </label>
          </div>
        </div>
        <button class="btn btn-block btn-primary" type="submit">
          {{ 'register.register' | cxTranslate }}
        </button>
        <a [routerLink]="{ cxRoute: 'login' } | cxUrl" class="cx-login-link btn-link mt-2">
          {{ 'register.signIn' | cxTranslate }}
        </a>
      </div>
    </div>

  </form>
</section>

<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
