<ng-container *ngIf="product$ | async as product">
  <ng-template
    [cxOutletContext]="{ product: product }"
    [cxOutlet]="outlets.PRICE">
    <div
      *ngIf="isAuthenticated | async"
      aria-label="new item price"
      class="price">
      {{ product?.price?.formattedValue }}
    </div>
  </ng-template>

</ng-container>
