<ng-template cxOutlet="cx-storefront">

  <div [class.sticky-top]="!isLaunchedInSmartEdit">
    <div class="top-container">
      <cx-page-layout section="top"></cx-page-layout>
    </div>

    <header
      (click)="collapseMenuIfClickOutside($event)"
      (keydown.escape)="collapseMenu()"
      [class.is-expanded]="isExpanded$ | async"
      [cxFocus]="{ disableMouseFocus: true }"
      cxSkipLink="cx-header">
      <cx-page-layout section="header"></cx-page-layout>
      <cx-page-layout section="jagaNavigation"></cx-page-layout>
      <cx-page-layout section="navigation"></cx-page-layout>
    </header>
    <cx-global-message></cx-global-message>
  </div>

  <cx-page-slot position="BottomHeaderSlot"></cx-page-slot>

  <main cxSkipLink="cx-main" [cxFocus]="{ disableMouseFocus: true }">
    <router-outlet></router-outlet>
  </main>

  <footer cxSkipLink="cx-footer" [cxFocus]="{ disableMouseFocus: true }">
    <cx-page-layout section="footer"></cx-page-layout>
    <cx-page-layout section="bottom"></cx-page-layout>
  </footer>
</ng-template>
