import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaModule } from '@spartacus/storefront';
import { JagaProductVariantGenericSelectorComponent } from './components/jaga-product-variant-generic-selector/jaga-product-variant-generic-selector.component';
import { JagaProductVariantsComponent } from './components/jaga-product-variants/jaga-product-variants.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { ProductVariantsGuard } from '@spartacus/product/variants/components';

@NgModule({
  declarations: [
    JagaProductVariantGenericSelectorComponent,
    JagaProductVariantsComponent,
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductVariantSelectorComponent: {
          component: JagaProductVariantsComponent,
          guards: [ProductVariantsGuard],
        },
      },
    } as CmsConfig),
    MediaModule,
  ],
})
export class JagaProductVariantsModule {}
