import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import {
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
} from '@spartacus/core';
import { MollieCheckoutService } from '../services/mollie-checkout.service';
import { combineLatest, Observable, of } from 'rxjs';
import { OrderFacade } from '@spartacus/order/root';
import { catchError, map, skipWhile, take, tap } from 'rxjs/operators';
import { CheckoutFacade } from '@spartacus/checkout/root';

@Injectable({
  providedIn: 'root',
})
export class MollieOrderConfirmationGuard implements CanActivate {
  constructor(
    protected routingService: RoutingService,
    protected checkoutService: MollieCheckoutService,
    protected globalMessageService: GlobalMessageService,
    protected orderFacade: OrderFacade,
    protected checkoutFacade: CheckoutFacade
  ) {}

  /**
   * Get the order details based on the :orderCode parameter from the URL.
   * If the order exists, we know the payment was successful and will redirect to the order confirmation page.
   * If the order does not exist, we will redirect back to the checkout and display a generic error message
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const orderCode = next.params.orderCode;
    this.orderFacade.loadOrderDetails(orderCode);
    this.checkoutFacade.loadCheckoutDetails('current');

    return combineLatest([
      this.orderFacade.getOrderHistoryList(1),
      this.orderFacade.getOrderDetails(),
      this.checkoutFacade.isLoading(),
    ]).pipe(
      skipWhile(([list, order, isLoading]) => list.orders.length < 1),
      skipWhile(([, , isLoading]) => isLoading),
      map(([, order]) => {
        if (Object.keys(order).length < 1) {
          throw new Error('Order creation failed');
        }

        this.checkoutFacade
          .getOrderDetails()
          .pipe(
            take(1),
            tap(() => this.routingService.go({ cxRoute: 'orderConfirmation' }))
          )
          .subscribe();

        this.checkoutService.confirmPlaceOrder(order);
        return true;
      }),
      catchError((error) => {
        console.warn(error);
        this.routingService.go({ cxRoute: 'checkoutReviewOrder' });
        this.globalMessageService.add(
          { key: 'payment.errors.failed' },
          GlobalMessageType.MSG_TYPE_ERROR
        );
        return of(false);
      })
    );
  }
}
