import { NgModule } from '@angular/core';
import { I18nConfig, provideConfig } from '@spartacus/core';
import { defaultCmsContentProviders, mediaConfig } from '@spartacus/storefront';
import { jagaLayoutConfig } from '../config/jaga-layout.config';
import { JagaTranslationChunksConfig } from '../jaga-translation/translations.config';

@NgModule({
  providers: [
    provideConfig(jagaLayoutConfig),
    provideConfig(mediaConfig),
    ...defaultCmsContentProviders,
    provideConfig(<I18nConfig>{
      i18n: {
        backend: {
          loadPath: '/translation/{{ns}}?lang={{lng}}',
        },
        chunks: JagaTranslationChunksConfig,
      },
    }),
  ],
})
export class SpartacusConfigurationModule {}
