export enum AreaOfExpertise {
  PLUMBING = 'plumbing',
  HEATING_CLIMATECONTROL_VENTILATION = 'heating-climatecontrol-ventilation',
  CONSTRUCTION = 'construction'
}

export enum NumberOfEmployees {
  ONE = '1',
  TWO_FIVE = '2-5',
  FIVE_TEN = '5-10',
  TEN_THIRTY = '10-30',
  THIRTY_FIFTY = '30-50',
  MORE_THAN_FIFTY = '>50'
}
