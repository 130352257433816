import { Component } from '@angular/core';
import { ProductIntroComponent } from '@spartacus/storefront';
import { AuthService, TranslationService, WindowRef } from '@spartacus/core';
import { Observable } from 'rxjs';
import { JagaProduct } from '../../models/product.model';
import { JagaCurrentProductService } from '../../jaga-current-product.service';

@Component({
  selector: 'jaga-product-intro',
  templateUrl: './jaga-product-intro.component.html'
})
export class JagaProductIntroComponent extends ProductIntroComponent {

  product$: Observable<JagaProduct> = this.currentProductService.getProduct();

  constructor(currentProductService: JagaCurrentProductService,
              translationService: TranslationService,
              winRef: WindowRef,
              protected authService: AuthService) {
    super(currentProductService, translationService, winRef);
  }

  get isAuthenticated(): Observable<boolean> {
    return this.authService.isUserLoggedIn();
  }

}
