import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CurrentProductService } from '@spartacus/storefront';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Product } from '@spartacus/core';

@Component({
  selector: 'jaga-product-variants',
  templateUrl: './jaga-product-variants.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JagaProductVariantsComponent implements OnInit {
  product$: Observable<Product>;

  constructor(protected currentProductService: CurrentProductService) {
  }

  ngOnInit() {
    this.product$ = this.currentProductService.getProduct()
      .pipe(
        filter(Boolean),
        filter((product: Product) =>
          !!(product.baseOptions || (product.multidimensional && product.variantMatrix))
        ),
        distinctUntilChanged()
      );
  }
}
