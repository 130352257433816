import { Component } from '@angular/core';
import { CurrentProductService, ProductImagesComponent } from '@spartacus/storefront';

@Component({
  selector: 'jaga-product-images',
  templateUrl: './jaga-product-images.component.html'
})
export class JagaProductImagesComponent extends ProductImagesComponent {
  constructor(currentProductService: CurrentProductService) {
    super(currentProductService);
  }
}
