import { Component } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { JagaOccSiteAdapter } from '../../../jaga-occ/adapters/jaga-occ-site.adapter';
import { ICON_TYPE } from '@spartacus/storefront';

@Component({
  selector: 'jaga-website-selector',
  templateUrl: './jaga-website-selector.component.html'
})

export class JagaWebsiteSelectorComponent {

  iconTypes = ICON_TYPE;
  baseStores$ = this.occSiteAdapter.loadBaseSites();

  constructor(
    private occSiteAdapter: JagaOccSiteAdapter,
    private windowsRef: WindowRef
  ) {
  }

  selectBaseSite($event) {
    this.windowsRef.nativeWindow.location.href = this.windowsRef.document.location.origin + '\\' + $event.target.value;
  }
}
