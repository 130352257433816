<ng-container *ngIf="mainImage$ | async as main">
  <jaga-media-zoom [container]="main"> </jaga-media-zoom>
</ng-container>

<ng-container *ngIf="thumbs$ | async as thumbs">
  <cx-carousel
    *ngIf="thumbs.length"
    class="thumbs mt-2"
    [items]="thumbs"
    itemWidth="120px"
    [hideIndicators]="false"
    [template]="thumb"
  ></cx-carousel>
</ng-container>

<ng-template #thumb let-item="item">
  <cx-media
    [container]="item.container"
    tabindex="0"
    (focus)="openImage(item.container)"
    [class.is-active]="isActive(item.container) | async">
  </cx-media>
</ng-template>
