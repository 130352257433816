import { Component, OnInit } from '@angular/core';
import { CartDetailsComponent } from '@spartacus/storefront';
import {
  ActiveCartService,
  AuthService,
  RoutingService,
  SelectiveCartService,
} from '@spartacus/core';

@Component({
  selector: 'jaga-cart-details',
  templateUrl: './jaga-cart-details.component.html',
})
export class JagaCartDetailsComponent
  extends CartDetailsComponent
  implements OnInit
{
  constructor(
    protected activeCartService: ActiveCartService,
    protected selectiveCartService: SelectiveCartService,
    protected authService: AuthService,
    protected routingService: RoutingService
  ) {
    super(activeCartService, selectiveCartService, authService, routingService);
  }
}
