import { Action } from '@ngrx/store';
import { ErrorModel, SearchConfig, Suggestion } from '@spartacus/core';

export const GET_QUICK_ORDER_SUGGESTIONS = '[QuickOrder] Get QuickOrder Suggestions';
export const GET_QUICK_ORDER_SUGGESTIONS_SUCCESS =
  '[QuickOrder] Get QuickOrder Suggestions Success';
export const GET_QUICK_ORDER_SUGGESTIONS_FAIL =
  '[QuickOrder] Get QuickOrder Suggestions Fail';
export const CLEAR_QUICK_ORDER_SEARCH_RESULT =
  '[QuickOrder] Clear QuickOrder Search Result';

export class GetQuickOrderSuggestions implements Action {
  readonly type = GET_QUICK_ORDER_SUGGESTIONS;

  constructor(public payload: { term: string; searchConfig: SearchConfig }) {
  }
}

export class GetQuickOrderSuggestionsSuccess implements Action {
  readonly type = GET_QUICK_ORDER_SUGGESTIONS_SUCCESS;

  constructor(public payload: Suggestion[]) {
  }
}

export class GetQuickOrderSuggestionsFail implements Action {
  readonly type = GET_QUICK_ORDER_SUGGESTIONS_FAIL;

  constructor(public payload: ErrorModel) {
  }
}

export class ClearQuickOrderSearchResult implements Action {
  readonly type = CLEAR_QUICK_ORDER_SEARCH_RESULT;

  constructor() {
  }
}

export type QuickOrderSearchAction =
  GetQuickOrderSuggestions
  | GetQuickOrderSuggestionsSuccess
  | GetQuickOrderSuggestionsFail
  | ClearQuickOrderSearchResult;
