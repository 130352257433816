import { Component, OnInit } from '@angular/core';
import {
  DeliveryMode,
  Occ,
  PaymentType,
  TranslationService,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { JagaOrder } from '../../../jaga-model/jaga-order.model';
import { Card } from '@spartacus/storefront/shared/components/card/card.component';
import { filter, map } from 'rxjs/operators';
import { OrderConfirmationOverviewComponent } from '@spartacus/checkout/components';
import { CheckoutFacade } from '@spartacus/checkout/root';
import Address = Occ.Address;

@Component({
  selector: 'jaga-order-confirmation-overview',
  templateUrl: './jaga-order-confirmation-overview.component.html',
})
export class JagaOrderConfirmationOverviewComponent
  extends OrderConfirmationOverviewComponent
  implements OnInit
{
  order$: Observable<JagaOrder>;

  constructor(
    checkoutService: CheckoutFacade,
    private translationService: TranslationService
  ) {
    super(checkoutService);
  }

  ngOnInit() {
    this.order$ = this.checkoutService.getOrderDetails();
  }

  getPaymentTypeCardContent(paymentType: PaymentType): Observable<Card> {
    return this.translationService.translate('paymentTypes.title').pipe(
      filter(() => Boolean(paymentType)),
      map((textTitle) => ({
        title: textTitle,
        textBold: paymentType.displayName,
      }))
    );
  }

  getAddressCardContent(deliveryAddress: Address): Observable<Card> {
    return this.translationService.translate('addressCard.shipTo').pipe(
      map((title) => {
        return this.getFormattedAddress(deliveryAddress, title);
      })
    );
  }

  getBillingAddressCardContent(billingAddress: Address): Observable<Card> {
    return this.translationService.translate('addressCard.billTo').pipe(
      map((title) => {
        return this.getFormattedAddress(billingAddress, title);
      })
    );
  }

  getFormattedAddress(address: Address, title: string) {
    let region = '';
    if (address.region && address.region.name) {
      region = address.region.name + ', ';
    }

    let name = '';
    if (address.firstName && address.lastName) {
      name = address.firstName + ' ' + address.lastName;
    }

    return {
      title,
      textBold: '',
      text: [
        name,
        address.companyName,
        address.line1,
        address.postalCode + ' ' + address.town,
        region + address.country.name,
        address.phone,
      ],
    };
  }

  getDeliveryModeCardContent(deliveryMode: DeliveryMode): Observable<Card> {
    return this.translationService
      .translate('orderDetails.shippingMethod')
      .pipe(
        filter(() => Boolean(deliveryMode)),
        map((textTitle) => ({
          title: textTitle,
          textBold: deliveryMode.name,
          text: [
            deliveryMode.description,
            deliveryMode.deliveryCost?.formattedValue
              ? deliveryMode.deliveryCost?.formattedValue
              : '',
          ],
        }))
      );
  }
}
