import { Component } from '@angular/core';
import { MiniCartComponent } from '@spartacus/storefront';
import { ActiveCartService } from '@spartacus/core';

@Component({
  selector: 'jaga-mini-cart',
  templateUrl: './jaga-mini-cart.component.html'
})
export class JagaMiniCartComponent extends MiniCartComponent {

  constructor(activeCartService: ActiveCartService) {
    super(activeCartService);
  }

}
