import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsImageComponent } from '../../models/jaga-cms.model';

@Component({
  selector: 'jaga-image',
  templateUrl: './jaga-image.component.html'
})
export class JagaImageComponent {

  constructor(public component: CmsComponentData<CmsImageComponent>) {
  }

}
