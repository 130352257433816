<ng-container *ngIf="product$ | async as product">
  <div [jagaHideNoContent]="!product?.classifications?.length" class="container">
    <h2>{{ 'productDetails.specification' | cxTranslate }}</h2>
    <table *ngFor="let classification of product?.classifications">
      <tr>
        <th colspan="2">
          <h3>{{ classification.name }}</h3>
        </th>
      </tr>

      <ng-container *ngIf="convertToFeatureGroups(classification.features) as featureGroups">
        <ng-container *ngFor="let groupingId of featureGroups.keys(); let i = index">
          <tr *ngIf="i > 0">
            <td colspan="2"></td>
          </tr>
          <tr *ngFor="let feature of featureGroups.get(groupingId)">
            <td>{{ feature.name }}</td>
            <td>
              <ul>
                <li *ngFor="let featureValue of feature?.featureValues">
                  {{ featureValue?.value }}
                </li>
              </ul>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </table>
  </div>
</ng-container>


