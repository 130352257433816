<div class="website-selector">
  <label *ngIf="(baseStores$ | async)?.length > 1 && (baseStores$ | async) as items">
    <select (change)="selectBaseSite($event)">
      <option *ngFor="let item of items"
              [selected]="item.selected"
              value="{{ item.defaultLanguage?.isocode }}">
        {{ item.name }}
      </option>
    </select>
    <cx-icon [type]="iconTypes.CARET_DOWN" class="small"></cx-icon>
  </label>
</div>
