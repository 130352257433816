import {OccConfig} from '@spartacus/core';

export const jagaOccUserConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        orderDetail: 'users/${userId}/orders/${orderId}?fields=FULL,billingAddress(FULL),paymentType(FULL)',
      },
    },
  },
};
