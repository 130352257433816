import { Component } from '@angular/core';
import { OrderConfirmationItemsComponent } from '@spartacus/checkout/components';
import { CheckoutService } from '@spartacus/checkout/core';

@Component({
  selector: 'jaga-order-confirmation-items-component',
  templateUrl: './jaga-order-confirmation-items-component.html',
})
export class JagaOrderConfirmationItemsComponent extends OrderConfirmationItemsComponent {
  constructor(checkoutService: CheckoutService) {
    super(checkoutService);
  }
}
