import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  CartActions,
  MultiCartSelectors,
  MultiCartService,
  OrderEntry,
  StateWithMultiCart,
  UserIdService,
} from '@spartacus/core';
import { JagaCart } from '../../jaga-checkout/core/models/jaga-cart.model';
import { ProcessesLoaderState } from '@spartacus/core/src/state/utils/processes-loader';

@Injectable({
  providedIn: 'root',
})
export class JagaMultiCartService extends MultiCartService {
  constructor(
    protected store: Store<StateWithMultiCart>,
    protected userIdService: UserIdService
  ) {
    super(store, userIdService);
  }

  getCart(cartId: string): Observable<JagaCart> {
    return this.store.pipe(
      select(MultiCartSelectors.getCartSelectorFactory(cartId))
    );
  }

  getCartEntity(cartId: string): Observable<ProcessesLoaderState<JagaCart>> {
    return this.store.pipe(
      select(MultiCartSelectors.getCartEntitySelectorFactory(cartId))
    );
  }
}
