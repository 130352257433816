import { Component } from '@angular/core';
import {
  AddedToCartDialogComponent,
  ModalService,
} from '@spartacus/storefront';
import { ActiveCartService } from '@spartacus/core';

@Component({
  selector: 'jaga-added-to-cart-dialog',
  templateUrl: './jaga-added-to-cart-dialog.component.html',
})
export class JagaAddedToCartDialogComponent extends AddedToCartDialogComponent {
  constructor(modalService: ModalService, cartService: ActiveCartService) {
    super(modalService, cartService);
  }
}
