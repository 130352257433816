<ng-container *ngIf="product">
  <div class="variant-generic-selector">
    <ng-container *ngFor="let variant of variants">
      <span>{{ variant[0].parentVariantCategory.name }}</span>

      <div
        *ngIf="variantHasImages(variant); else noImages"
        class="image-variant-container">

        <div *ngFor="let cat of variant">
          <cx-media
            (click)="changeVariant(cat.variantOption?.code)"
            [class.active]="cat.variantOption?.code === product.code"
            [container]="cat.variantOption?.variantOptionQualifiers[0]?.image">
          </cx-media>
          {{cat.variantValueCategory?.name}}
        </div>
      </div>

      <ng-template #noImages>
        <div class="select-variant-container">
          <select (change)="changeVariant($event.target.value)">
            <option
              *ngFor="let cat of variant"
              [selected]="cat.variantOption.code === product.code"
              [value]="cat.variantOption.code">
              {{ cat.variantValueCategory.name }}
            </option>
          </select>
        </div>
      </ng-template>
    </ng-container>
  </div>
</ng-container>
