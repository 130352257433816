import { Component } from '@angular/core';

@Component({
  selector: 'jaga-map',
  templateUrl: './jaga-map.component.html'
})
export class JagaMapComponent {
  readonly JagaMapUrl = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2516.152906098933!2d5.4296636516469805!3d50.90238677943987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c11ff78b2096ff%3A0xaadad52b52732255!2sJaga!5e0!3m2!1snl!2sbe!4v1611844663505!5m2!1snl!2sbe';

  constructor() { }

}
