import { Injectable } from '@angular/core';
import { MollieCheckoutAdapter } from './mollie-checkout.adapter';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MollieCheckoutConnector {

  constructor(protected adapter: MollieCheckoutAdapter) {
  }

  public createOrder(userId: string, cartId: string, termsChecked: boolean): Observable<string> {
    return this.adapter.createOrder(userId, cartId, termsChecked);
  }
}
