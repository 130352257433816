import { Component, ElementRef } from '@angular/core';
import {
  HamburgerMenuService,
  KeyboardFocusService,
  StorefrontComponent,
} from '@spartacus/storefront';
import { RoutingService } from '@spartacus/core';
import { SmartEditLauncherService } from '@spartacus/smartedit/root';

@Component({
  selector: 'jaga-root',
  templateUrl: './app.component.html',
})
export class AppComponent extends StorefrontComponent {
  title = 'jaga-storefront';

  constructor(
    hamburgerMenuService: HamburgerMenuService,
    protected routeService: RoutingService,
    protected elementRef: ElementRef,
    protected keyboardFocusService: KeyboardFocusService,
    private smartEditService: SmartEditLauncherService
  ) {
    super(hamburgerMenuService, routeService, elementRef, keyboardFocusService);
  }

  get isLaunchedInSmartEdit(): boolean {
    return this.smartEditService.isLaunchedInSmartEdit();
  }
}
