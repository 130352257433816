import { Component } from '@angular/core';
import { RoutingService, TranslationService } from '@spartacus/core';
import { OrderHistoryComponent } from '@spartacus/order/components';
import { OrderFacade, ReplenishmentOrderFacade } from '@spartacus/order/root';

@Component({
  selector: 'jaga-order-history',
  templateUrl: './jaga-order-history.component.html',
})
export class JagaOrderHistoryComponent extends OrderHistoryComponent {
  constructor(
    routing: RoutingService,
    userOrderService: OrderFacade,
    translation: TranslationService,
    userReplenishmentOrderService: ReplenishmentOrderFacade
  ) {
    super(
      routing,
      userOrderService,
      translation,
      userReplenishmentOrderService
    );
  }
}
