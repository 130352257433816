import { Action } from '@ngrx/store';
import { Order } from '@spartacus/core';

export const MOLLIE_CREATE_ORDER = '[Mollie] Create Order';
export const MOLLIE_CREATE_ORDER_FAIL = '[Mollie] Create Order Fail';
export const MOLLIE_CREATE_ORDER_SUCCESS = '[Mollie] Create Order Success';

export const MOLLIE_CONFIRM_PLACE_ORDER = '[Mollie] Confirm Place Order';

export class MollieCreateOrder implements Action {
  readonly type = MOLLIE_CREATE_ORDER;

  constructor(public payload: { userId: string; cartId: string, termsChecked: boolean }) {
  }
}

export class MollieCreateOrderFail implements Action {
  readonly type = MOLLIE_CREATE_ORDER_FAIL;

  constructor(public payload: any | null) {
  }
}

export class MollieCreateOrderSuccess implements Action {
  readonly type = MOLLIE_CREATE_ORDER_SUCCESS;

  constructor(public payload: string) {
  }
}


export class MollieConfirmPlaceOrder implements Action {
  readonly type = MOLLIE_CONFIRM_PLACE_ORDER;

  constructor(public payload: Order) {
  }
}
