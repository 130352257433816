import { effects } from './store/effects';
import { MollieOccConfig } from './config';
import { MollieCheckoutService } from './services/mollie-checkout.service';
import { MollieCheckoutAdapter } from './connectors/checkout';
import { OccMollieCheckoutAdapter } from './occ/checkout/occ-mollie-checkout.adapter';
import { MollieOrderConfirmationGuard } from './guards/mollie-order-confirmation.guard';
import { MollieUserOrderService } from './services/mollie-user-order.service';
import { MollieHttpInterceptorsModule } from './http-interceptors/mollie-http-interceptors.module';
import { EffectsModule } from '@ngrx/effects';
import { RouterModule } from '@angular/router';
import { provideDefaultConfig, UserOrderService } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { OrderConfirmationOverviewComponent } from '@spartacus/checkout/components';
import { CheckoutService } from '@spartacus/checkout/core';

export const defaultMollieOccConfig: MollieOccConfig = {
  backend: {
    occ: {
      endpoints: {
        molliePlaceOrder: 'users/${userId}/mollie/order/create',
      },
    },
  },
};

@NgModule({
  imports: [
    EffectsModule.forFeature(effects),
    RouterModule.forChild([
      {
        path: 'mollie/order/:orderCode',
        component: OrderConfirmationOverviewComponent,
        canActivate: [MollieOrderConfirmationGuard],
      },
    ]),
    MollieHttpInterceptorsModule,
  ],
  providers: [
    provideDefaultConfig(defaultMollieOccConfig),
    {
      provide: MollieCheckoutAdapter,
      useExisting: OccMollieCheckoutAdapter,
    },
    {
      provide: CheckoutService,
      useExisting: MollieCheckoutService,
    },
    {
      provide: UserOrderService,
      useExisting: MollieUserOrderService,
    },
  ],
})
export class MollieCheckoutModule {}
