import { AfterViewChecked, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsAccordionParagraphComponent } from '../../../jaga-model/jaga-accordion-paragraph.model';
import { ActivatedRoute } from '@angular/router';
import { flatMap, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';


@Component({
  selector: 'jaga-accordion-paragraph',
  templateUrl: './jaga-accordion-paragraph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JagaAccordionParagraphComponent implements OnInit, OnDestroy, AfterViewChecked {
  showContent = false;
  anchorId: string;

  private subscription = new Subscription();
  private shouldScrollIntoView;

  constructor(public component: CmsComponentData<CmsAccordionParagraphComponent>,
              protected route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.component.data$.pipe(
        take(1),
        flatMap((data: CmsAccordionParagraphComponent) => {
          this.anchorId = data.anchor;
          return this.route.fragment;
        })
      ).subscribe((fragment: string) => {
        this.shouldScrollIntoView = fragment === this.anchorId;
        this.showContent = this.showContent || this.shouldScrollIntoView;
      })
    );
  }

  ngAfterViewChecked(): void {
    if (this.shouldScrollIntoView) {
      document.querySelector('#' + this.anchorId).scrollIntoView();
      this.shouldScrollIntoView = false;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
