import { QuickOrderEntry } from '../../models/quick-order.model';
import { Action } from '@ngrx/store';

export const ADD_QUICK_ORDER_ENTRY = '[QuickOrder] Add Entry';
export const ADD_QUICK_ORDER_ENTRY_SUCCESS = '[QuickOrder] Add Entry Success';
export const ADD_QUICK_ORDER_ENTRY_FAIL = '[QuickOrder] Add Entry Fail';

export const CHANGE_QUICK_ORDER_QUANTITY_ENTRY = '[QuickOrder] Change Entry Quantity';

export const REMOVE_QUICK_ORDER_ENTRY = '[QuickOrder] Remove Entry';
export const CLEAR_QUICK_ORDER_ENTRIES = '[QuickOrder] Clear Entries';

export const ADD_QUICK_ORDER_TO_CART = '[QuickOrder] Add Quick Order To Cart';
export const ADD_QUICK_ORDER_TO_CART_SUCCESS = '[QuickOrder] Add Quick Order To Cart Success';
export const ADD_QUICK_ORDER_TO_CART_FAIL = '[QuickOrder] Add Quick Order To Cart Fail';

export class QuickOrderAddEntry implements Action {
  readonly type = ADD_QUICK_ORDER_ENTRY;

  constructor(
    public code: string
  ) {
  }
}

export class QuickOrderAddEntryFail implements Action {
  readonly type = ADD_QUICK_ORDER_ENTRY_FAIL;

  constructor(public errorMessage: string) {
  }
}

export class QuickOrderAddEntrySuccess implements Action {
  readonly type = ADD_QUICK_ORDER_ENTRY_SUCCESS;

  constructor(public payload: QuickOrderEntry) {
  }
}

export class QuickOrderChangeQuantityEntry implements Action {
  readonly type = CHANGE_QUICK_ORDER_QUANTITY_ENTRY;

  constructor(
    public id: string,
    public changes: Partial<QuickOrderEntry>
  ) {
  }
}

export class QuickOrderRemoveEntry implements Action {
  readonly type = REMOVE_QUICK_ORDER_ENTRY;

  constructor(
    public id: string
  ) {
  }
}

export class ClearQuickOrderEntries implements Action {
  readonly type = CLEAR_QUICK_ORDER_ENTRIES;

  constructor() {
  }
}

export class QuickOrderAddToCart implements Action {
  readonly type = ADD_QUICK_ORDER_TO_CART;

  constructor(
    public minEntries: number,
    public entries: QuickOrderEntry[]) {
  }
}

export class QuickOrderAddToCartSuccess implements Action {
  readonly type = ADD_QUICK_ORDER_TO_CART_SUCCESS;

  constructor(
    public successMessage: string,
    public entries: QuickOrderEntry[]
  ) {
  }
}

export class QuickOrderAddToCartFail implements Action {
  readonly type = ADD_QUICK_ORDER_TO_CART_FAIL;

  constructor(
    public errorMessage: string
  ) {
  }
}

export type QuickOrderEntriesActions = QuickOrderAddEntry
  | QuickOrderAddEntryFail
  | QuickOrderAddEntrySuccess
  | QuickOrderChangeQuantityEntry
  | QuickOrderRemoveEntry
  | ClearQuickOrderEntries
  | QuickOrderAddToCart
  | QuickOrderAddToCartSuccess
  | QuickOrderAddToCartFail;

