import { TranslationChunksConfig } from '@spartacus/core';

export const JagaTranslationChunksConfig: TranslationChunksConfig = {
  address: ['addressCard', 'addressForm'],
  common: [
    'carousel',
    'contact',
    'crossSell',
    'formErrors',
    'httpHandlers',
    'navigation',
    'miniCart',
    'miniLogin',
    'pageMetaResolver',
    'reset',
    'search',
    'searchBox',
    'skipLink',
    'sorting',
    'spinner',
  ],
  cart: ['cartDetails', 'cartItems', 'orderCost', 'voucher'],
  checkout: [
    'checkoutAddress',
    'checkoutProgress',
    'checkoutShipping',
    'checkoutOrderConfirmation',
    'checkoutPO',
    'checkoutReview',
    'remarks',
  ],
  mollie: ['payment'],
  myAccount: [
    'orderDetails',
    'orderHistory',
    'updatePasswordForm',
    'updateProfileForm',
  ],
  payment: ['paymentTypes', 'paymentMethods'],
  product: [
    'addToCart',
    'itemCounter',
    'productDetails',
    'productList',
    'productSummary',
    'productView',
    'TabPanelContainer',
    'jaga-loginToBuy',
  ],
  user: ['anonymousConsents', 'loginForm', 'forgottenPassword', 'register'],
};
