<ng-container *ngIf="product$ | async as product">
  <div [jagaHideNoContent]="!product?.manuals?.length && !product?.datasheets?.length"
       class="d-flex flex-wrap align-items-start">
    <a *ngFor="let manual of product.manuals" href="{{manual.url}}"
       target="_blank">
      <cx-media
        [alt]="manual.image?.altText"
        [container]="manual.image"
        [format]="manual.image?.format"></cx-media>
      {{ manual.name }}
    </a>

    <a *ngFor="let datasheet of product.datasheets" href="{{datasheet.url}}"
       target="_blank">
      <cx-media
        [alt]="datasheet.image?.altText"
        [container]="datasheet.image"
        [format]="datasheet.image?.format"></cx-media>
      {{ datasheet.name }}
    </a>
  </div>
</ng-container>
