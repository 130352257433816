import { NgModule } from '@angular/core';
import { ASM_FEATURE, AsmRootModule } from '@spartacus/asm/root';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  declarations: [],
  imports: [AsmRootModule],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [ASM_FEATURE]: {
          module: () => import('@spartacus/asm').then((m) => m.AsmModule),
        },
      },
    }),
  ],
})
export class AsmFeatureModule {}
