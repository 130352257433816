<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="entries$ | async as entries">
    <div *ngIf="cart.totalItems > 0" class="cart-details-wrapper">
      <div class="cx-total h1 pt-4">
        {{ "cartDetails.cartName" | cxTranslate : { code: cart.code } }}
      </div>

      <cx-promotions
        [promotions]="
          (cart.appliedOrderPromotions || []).concat(
            cart.potentialOrderPromotions || []
          )
        "
      ></cx-promotions>

      <jaga-cart-item-list
        [items]="entries"
        [cartIsLoading]="!(cartLoaded$ | async)"
        [promotionLocation]="promotionLocation"
        [options]="{
          isSaveForLater: false,
          optionalBtn: saveForLaterBtn
        }"
      ></jaga-cart-item-list>
    </div>
  </ng-container>
</ng-container>

<ng-template let-ctx #saveForLaterBtn>
  <div
    *ngIf="selectiveCartEnabled"
    class="col-md-3 col-lg-3 col-xl-3 cx-sfl-btn"
  >
    <button
      class="link cx-action-link"
      [disabled]="ctx.loading"
      (click)="saveForLater(ctx.item)"
      type="button"
    >
      {{ "saveForLaterItems.saveForLater" | cxTranslate }}
    </button>
  </div>
</ng-template>
