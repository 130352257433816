import { Action } from '@ngrx/store';
import { ValidationErrors } from '@angular/forms';

export const UPDATE_RESET_PASSWORD_ERRORS = '[JAGA Reset Password] Update reset password errors';
export const CLEAR_RESET_PASSWORD_ERRORS = '[JAGA Reset Password] Clear reset password errors';

export class UpdateResetPasswordErrorsAction implements Action {
  readonly type = UPDATE_RESET_PASSWORD_ERRORS;

  constructor(public payload: ValidationErrors) {
  }
}

export class ClearResetPasswordErrorsAction implements Action {
  readonly type = CLEAR_RESET_PASSWORD_ERRORS;
}

export type JagaResetPasswordActions = UpdateResetPasswordErrorsAction | ClearResetPasswordErrorsAction;
