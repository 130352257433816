<ng-container *ngIf="product$ | async as product">
  <h2 [innerHTML]="product.groupDescription"
      class="h2">
  </h2>

  <div class="product-info-container">
    <div class="product-info">
      <p [innerHTML]="product.name" class="name"></p>

      <div class="code">
        {{ 'productSummary.id' | cxTranslate }} {{ product?.code }}
      </div>
    </div>
    <div class="product-logos">
      <cx-media
        *ngFor="let logo of product.logos"
        [container]="logo">
      </cx-media>
    </div>
  </div>

  <div [class.jaga-hybrid-assignments]="product?.coolingAssignments?.length && product?.heatingAssignments?.length"
       class="jaga-product-assignments">
    <ul *ngIf="product?.coolingAssignments?.length"
        class="jaga-cooling-assignments">
      <li *ngFor="let assignment of product?.coolingAssignments"
          [innerHTML]="assignment"></li>
    </ul>

    <ul *ngIf="product?.heatingAssignments?.length"
        class="jaga-heating-assignments">
      <li
        *ngFor="let assignment of product?.heatingAssignments"
        [innerHTML]="assignment"></li>
    </ul>
  </div>

  <ng-template [ngIf]="!(isAuthenticated | async)">
    <a [routerLink]="{ cxRoute: 'login' } | cxUrl"
       class="btn btn-block btn-cta mt-4">
      {{ 'jaga-loginToBuy' | cxTranslate }}
    </a>
  </ng-template>
</ng-container>
