<div class="jaga-product-grid-item">
  <a
    [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
    class="cx-product-image-container"
    tabindex="-1">
    <cx-media
      [alt]="product.summary"
      [container]="product.images?.PRIMARY"
      class="cx-product-image">
    </cx-media>
  </a>
  <a
    [innerHTML]="product.groupDescription"
    [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
    class="cx-product-group-description">
  </a>
  <a
    [innerHTML]="product.nameHtml"
    [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
    class="cx-product-name">
  </a>

  <a [class.jaga-hybrid-assignments]="product?.coolingAssignments && product?.heatingAssignments"
     [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
     class="jaga-product-assignments">
    <ul *ngIf="product?.coolingAssignments"
        class="jaga-cooling-assignments">
      <li *ngFor="let assignment of product?.coolingAssignments"
          [innerHTML]="assignment"></li>
    </ul>

    <ul *ngIf="product?.heatingAssignments"
        class="jaga-heating-assignments">
      <li
        *ngFor="let assignment of product?.heatingAssignments"
        [innerHTML]="assignment"></li>
    </ul>
  </a>

  <div
    *ngIf="isAuthenticated | async"
    class="cx-product-price-container">
    <div aria-label="Product price" class="cx-product-price">
      {{ product.price?.formattedValue }}
    </div>
  </div>

  <div *ngIf="product.variantOptions" class="cx-variant-style-icons">
    <cx-variant-style-icons
      [variants]="product.variantOptions"
    ></cx-variant-style-icons>
  </div>

  <jaga-add-to-cart
    *ngIf="product.stock?.stockLevelStatus !== 'outOfStock' && isAuthenticated | async"
    [product]="product"
    [showQuantity]="true">
  </jaga-add-to-cart>
</div>
