<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="entries$ | async as entries">
    <jaga-order-summary [cart]="cart" [showAppliedCoupons]="false"></jaga-order-summary>
    <div class="button-wrapper">
      <button
        (click)="backClicked()"
        class="btn btn-secondary"
        type="button">
        {{ 'cartDetails.continueShopping' | cxTranslate }}
      </button>
      <button
        *ngIf="entries.length"
        [routerLink]="{ cxRoute: 'checkout' } | cxUrl"
        class="btn btn-primary"
        type="button">
        {{ 'cartDetails.proceedToCheckout' | cxTranslate }}
      </button>
    </div>
  </ng-container>
</ng-container>
