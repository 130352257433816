<cx-carousel
  [items]="items$ | async"
  [template]="carouselItem"
  [title]="title$ | async"
  itemWidth="285px">
</cx-carousel>

<ng-template #carouselItem let-item="item">
  <a [routerLink]="{ cxRoute: 'product', params: item } | cxUrl" class="flex-grow-1" tabindex="0">
    <cx-media [container]="item.images?.PRIMARY"></cx-media>
    <h4 class="mb-2">
      {{ item.groupDescription }}
    </h4>
    <p class="mb-1">
      {{ item.name }}
    </p>
    <div *ngIf="isAuthenticated | async" class="price mb-1 mt-auto">
      {{ item.price?.formattedValue }}
    </div>
  </a>

  <jaga-add-to-cart
    *ngIf="item.stock?.stockLevelStatus !== 'outOfStock' && isAuthenticated | async"
    [product]="item"
    class="d-block mx-auto mt-2 mb-4">
  </jaga-add-to-cart>
</ng-template>
