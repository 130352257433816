import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CurrentProductService, ProductSummaryComponent } from '@spartacus/storefront';
import { AuthService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'jaga-product-summary',
  templateUrl: './jaga-product-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JagaProductSummaryComponent extends ProductSummaryComponent {
  constructor(protected currentProductService: CurrentProductService,
              protected authService: AuthService) {
    super(currentProductService);
  }

  get isAuthenticated(): Observable<boolean> {
    return this.authService.isUserLoggedIn();
  }

}
