<ng-container *ngIf="order$ | async as order">
  <!-- consigned entries -->
  <ng-container *ngIf="order.consignments?.length">
    <cx-order-consigned-entries
      *ngIf="others$ | async as others"
      [order]="order"
      [consignments]="others"
    ></cx-order-consigned-entries>

    <cx-order-consigned-entries
      *ngIf="completed$ | async as completed"
      [order]="order"
      [consignments]="completed"
    ></cx-order-consigned-entries>

    <cx-order-consigned-entries
      *ngIf="cancel$ | async as cancel"
      [order]="order"
      [consignments]="cancel"
    ></cx-order-consigned-entries>
  </ng-container>

  <!-- unconsigned entries -->
  <ng-container *ngIf="order.unconsignedEntries?.length">
    <div class="cx-list row">
      <div class="cx-list-item col-12">
        <jaga-cart-item-list
          [items]="order?.unconsignedEntries"
          [readonly]="true"
          [promotionLocation]="promotionLocation"
        ></jaga-cart-item-list>
      </div>
    </div>
  </ng-container>
</ng-container>
