import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JagaQuickOrderListComponent } from './components/jaga-quick-order-list/jaga-quick-order-list.component';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { JagaCommonModule } from '../jaga-common/jaga-common.module';
import { QuickOrderStoreModule } from './store/quick-order.store.module';
import { JagaQuickOrderItemComponent } from './components/jaga-quick-order-item/jaga-quick-order-item.component';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { JagaQuickOrderSearchComponent } from './components/jaga-quick-order-search/jaga-quick-order-search.component';

@NgModule({
  declarations: [
    JagaQuickOrderListComponent,
    JagaQuickOrderItemComponent,
    JagaQuickOrderSearchComponent
  ],
  imports: [
    ConfigModule.withConfig({
      cmsComponents: {
        CMSQuickOrderComponent: {
          component: JagaQuickOrderListComponent
        }
      }
    } as CmsConfig),
    CommonModule,
    I18nModule,
    JagaCommonModule,
    QuickOrderStoreModule,
    UrlModule,
    MediaModule,
    RouterModule,
    FormsModule,
    IconModule
  ]
})
export class JagaQuickOrderModule {
}
