import { Order, StateUtils } from '@spartacus/core';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { OrderState, StateWithOrder } from '@spartacus/order/core';
import { ORDER_FEATURE } from '@spartacus/order/root';

export const getOrderState: MemoizedSelector<StateWithOrder, OrderState> =
  createFeatureSelector<OrderState>(ORDER_FEATURE);

export const getOrderDetailState: MemoizedSelector<
  StateWithOrder,
  StateUtils.LoaderState<Order>
> = createSelector(getOrderState, (state: OrderState) => {
  return state.orderDetail;
});

export const getOrderDetails: MemoizedSelector<StateWithOrder, Order> =
  createSelector(getOrderDetailState, (state: StateUtils.LoaderState<Order>) =>
    StateUtils.loaderValueSelector(state)
  );

export const getOrderDetailsError: MemoizedSelector<StateWithOrder, boolean> =
  createSelector(getOrderDetailState, (state: StateUtils.LoaderState<Order>) =>
    StateUtils.loaderErrorSelector(state)
  );
