import { Component, OnInit } from '@angular/core';
import {
  UpdateProfileComponent,
  UpdateProfileComponentService,
} from '@spartacus/user/profile/components';
import { Observable } from 'rxjs';
import { UserProfileFacade } from '@spartacus/user/profile/root';

@Component({
  selector: 'jaga-update-profile',
  templateUrl: './jaga-update-profile.component.html',
})
export class JagaUpdateProfileComponent
  extends UpdateProfileComponent
  implements OnInit
{
  user$: Observable<any>;

  constructor(
    protected service: UpdateProfileComponentService,
    protected userProfileFacade: UserProfileFacade
  ) {
    super(service);
  }

  ngOnInit() {
    this.user$ = this.userProfileFacade.get();
  }
}
