import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { QuickOrderEntry } from '../models/quick-order.model';
import { OrderEntry } from '@spartacus/core';
import { Injectable } from '@angular/core';
import { QuickOrderSelectors } from '../store/selectors';
import { QuickOrderActions } from '../store/actions';
import { StateWithQuickOrder } from '../store/quick-order-state';

@Injectable({
  providedIn: 'root'
})
export class QuickOrderService {

  constructor(private store: Store<StateWithQuickOrder>) {
  }

  getEntries(): Observable<QuickOrderEntry[]> {
    return this.store.select(QuickOrderSelectors.selectAll);
  }

  addEntry(code: string): void {
    this.store.dispatch(new QuickOrderActions.QuickOrderAddEntry(code));
  }

  changeQuantity(oldEntry: QuickOrderEntry, qty: number): void {
    const replacedEntry: QuickOrderEntry = {
      product: oldEntry.product,
      quantity: qty,
      basePrice: oldEntry.basePrice,
    };
    this.store.dispatch(new QuickOrderActions.QuickOrderChangeQuantityEntry(oldEntry.product.code, replacedEntry));
  }

  removeEntry(entry: QuickOrderEntry): void {
    this.store.dispatch(new QuickOrderActions.QuickOrderRemoveEntry(entry.product.code));
  }

  clearEntries(): void {
    this.store.dispatch(new QuickOrderActions.ClearQuickOrderEntries());
  }

  addToCart(minRows: number, entries: QuickOrderEntry[]): void {
    this.store.dispatch(new QuickOrderActions.QuickOrderAddToCart(minRows, entries));
  }

  getCartEntries(entries: QuickOrderEntry[]): OrderEntry[] {
    const cartEntries: OrderEntry[] = [];
    entries.forEach((entry: QuickOrderEntry) => {
      cartEntries.push({
        basePrice: entry.basePrice,
        product: entry.product,
        quantity: entry.quantity,
      } as OrderEntry);
    });
    return cartEntries;
  }
}
