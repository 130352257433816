import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { normalizeHttpError, ProductSearchConnector } from '@spartacus/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { QuickOrderActions } from '../actions';
import { Observable, of } from 'rxjs';

@Injectable()
export class QuickOrderSearchEffects {

  
  getProductSuggestions$: Observable<| QuickOrderActions.GetQuickOrderSuggestionsSuccess
    | QuickOrderActions.GetQuickOrderSuggestionsFail> = createEffect(() => this.actions$.pipe(
    ofType(QuickOrderActions.GET_QUICK_ORDER_SUGGESTIONS),
    map((action: QuickOrderActions.GetQuickOrderSuggestions) => action.payload),
    switchMap((payload) => {
      return this.productSearchConnector
        .getSuggestions(payload.term, payload.searchConfig.pageSize)
        .pipe(
          map((suggestions) => {
            if (suggestions === undefined) {
              return new QuickOrderActions.GetQuickOrderSuggestionsSuccess([]);
            }
            return new QuickOrderActions.GetQuickOrderSuggestionsSuccess(suggestions);
          }),
          catchError((error) =>
            of(new QuickOrderActions.GetQuickOrderSuggestionsFail(normalizeHttpError(error)))
          )
        );
    })
  ));

  constructor(
    private actions$: Actions,
    private productSearchConnector: ProductSearchConnector
  ) {
  }
}
