import { Component, Input } from '@angular/core';
import { AuthService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { JagaProduct } from '../../../models/product.model';

@Component({
  selector: 'jaga-product-base-item',
  template: ''
})
export class JagaProductBaseItemComponent {
  @Input() product: JagaProduct;

  constructor(protected authService: AuthService) {
  }

  get isAuthenticated(): Observable<boolean> {
    return this.authService.isUserLoggedIn();
  }

}
