<ng-container *ngIf="items$ | async as items">
  <div *ngIf="items.length" class="JagaProductReferences">
    <cx-carousel
      [items]="items"
      [template]="carouselItem"
      [title]="title$ | async"
    >
    </cx-carousel>
  </div>

  <ng-template #carouselItem let-item="item">
    <a [routerLink]="{ cxRoute: 'product', params: item } | cxUrl" class="flex-grow-1" tabindex="0">
      <cx-media [container]="item.images?.PRIMARY"></cx-media>
      <h4>{{ item.name }}</h4>
      <div *ngIf="isAuthenticated | async" class="price mb-1 mt-auto">
        {{ item.price?.formattedValue }}
      </div>
    </a>

    <jaga-add-to-cart
      *ngIf="item.stock?.stockLevelStatus !== 'outOfStock' && isAuthenticated | async"
      [product]="item"
      class="d-block mx-auto mt-2 mb-4">
    </jaga-add-to-cart>
  </ng-template>
</ng-container>
