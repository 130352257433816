import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService, CmsProductReferencesComponent, ProductReferenceService } from '@spartacus/core';
import { CmsComponentData, CurrentProductService, ProductReferencesComponent } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'jaga-product-references',
  templateUrl: './jaga-product-references.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JagaProductReferencesComponent extends ProductReferencesComponent {

  constructor(
    protected component: CmsComponentData<CmsProductReferencesComponent>,
    protected current: CurrentProductService,
    protected referenceService: ProductReferenceService,
    private authService: AuthService) {
    super(component, current, referenceService);
  }

  get isAuthenticated(): Observable<boolean> {
    return this.authService.isUserLoggedIn();
  }

}
