import { Component } from '@angular/core';
import { AuthService, UserService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { JagaUser } from '../../models/jaga-user';
import { skipWhile, switchMap, tap } from 'rxjs/operators';
import { JagaUserBaseSiteService } from '../../../jaga-website/services/jaga-user-base-site.service';

@Component({
  selector: 'jaga-login',
  templateUrl: './jaga-login.component.html',
  styleUrls: ['./jaga-login.component.scss'],
})
export class JagaLoginComponent {
  user$: Observable<JagaUser> = this.auth.isUserLoggedIn().pipe(
    skipWhile((isUserLoggedIn) => !isUserLoggedIn),
    switchMap(() => {
      return this.userBaseSiteService.redirectToUserBaseSite();
    })
  );

  constructor(
    private auth: AuthService,
    private userService: UserService,
    private userBaseSiteService: JagaUserBaseSiteService
  ) {}
}
