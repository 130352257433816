import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsParagraphComponent } from '@spartacus/core';

import isHtml from 'is-html';

@Component({
  selector: 'jaga-paragraph',
  templateUrl: './jaga-paragraph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JagaParagraphComponent {

  constructor(public component: CmsComponentData<CmsParagraphComponent>) {
  }

  isHtml(value: string): boolean {
    return isHtml(value);
  }

}
