import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AddressBookComponentService,
  CardModule,
  FormErrorsModule,
  SpinnerModule,
} from '@spartacus/storefront';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  I18nModule,
  UserAddressService,
} from '@spartacus/core';
import { ReactiveFormsModule } from '@angular/forms';
import { JagaResetPasswordComponent } from './components/jaga-reset-password/jaga-reset-password.component';
import { JagaCommonModule } from '../jaga-common/jaga-common.module';
import { JagaAddressBookComponent } from './components/jaga-address-book/jaga-address-book.component';
import { jagaOccUserConfig } from './config/jaga-occ-user-config';
import { JagaOrderModule } from './components/jaga-order/jaga-order.module';
import { JagaUpdatePasswordModule } from './components/jaga-update-password/jaga-update-password.module';
import { JagaUpdateProfileModule } from './components/jaga-update-profile/jaga-update-profile.module';

@NgModule({
  declarations: [JagaResetPasswordComponent, JagaAddressBookComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig(jagaOccUserConfig),
    ConfigModule.withConfig({
      cmsComponents: {
        ResetPasswordComponent: {
          component: JagaResetPasswordComponent,
        },
        AccountAddressBookComponent: {
          component: JagaAddressBookComponent,
          providers: [
            {
              provide: AddressBookComponentService,
              useClass: AddressBookComponentService,
              deps: [UserAddressService],
            },
          ],
          guards: [AuthGuard],
        },
      },
    } as CmsConfig),
    SpinnerModule,
    I18nModule,
    ReactiveFormsModule,
    FormErrorsModule,
    JagaCommonModule,
    CardModule,
    JagaOrderModule,
    JagaUpdatePasswordModule,
    JagaUpdateProfileModule,
  ],
})
export class JagaMyAccountModule {}
