import { Component, OnInit } from '@angular/core';
import { from, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AuthRedirectService,
  AuthService,
  GlobalMessageService,
  GlobalMessageType,
  WindowRef,
} from '@spartacus/core';
import { ActivatedRoute } from '@angular/router';
import { CustomFormValidators } from '@spartacus/storefront';
import { CheckoutConfigService } from '@spartacus/checkout/components';
import { tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'jaga-login-form-component',
  templateUrl: './jaga-login-form.component.html',
})
export class JagaLoginFormComponent implements OnInit {
  sub: Subscription;
  loginForm: FormGroup;
  loginAsGuest = false;

  constructor(
    protected auth: AuthService,
    protected globalMessageService: GlobalMessageService,
    protected fb: FormBuilder,
    protected winRef: WindowRef,
    protected activatedRoute: ActivatedRoute,
    protected checkoutConfigService: CheckoutConfigService
  ) {}

  ngOnInit() {
    const routeState = this.winRef.nativeWindow?.history?.state;
    const prefilledEmail = routeState?.newUid;

    this.loginForm = this.fb.group({
      userId: [
        prefilledEmail?.length ? prefilledEmail : '',
        [Validators.required, CustomFormValidators.emailValidator],
      ],
      password: ['', Validators.required],
    });

    if (this.checkoutConfigService.isGuestCheckout()) {
      this.loginAsGuest = this.activatedRoute?.snapshot?.queryParams?.forced;
    }
  }

  submitForm() {
    if (this.loginForm.valid) {
      this.loginUser();
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  protected loginUser(): void {
    const { userId, password } = this.loginForm.controls;

    from(
      this.auth.loginWithCredentials(
        userId.value.toLowerCase(), // backend accepts lowercase emails only
        password.value
      )
    )
      .pipe(
        withLatestFrom(this.auth.isUserLoggedIn()),
        tap(([_, isLoggedIn]) => this.onSuccess(isLoggedIn))
      )
      .subscribe();
  }

  protected onSuccess(isLoggedIn: boolean): void {
    if (isLoggedIn) {
      // We want to remove error messages on successful login (primary the bad
      // username/password combination)
      this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
      this.loginForm.reset();
    }
  }
}
