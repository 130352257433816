import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CardModule,
  FormErrorsModule,
  SpinnerModule,
} from '@spartacus/storefront';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  I18nModule,
} from '@spartacus/core';
import { ReactiveFormsModule } from '@angular/forms';
import { JagaCommonModule } from '../../../jaga-common/jaga-common.module';
import { JagaUpdateProfileComponent } from './jaga-update-profile.component';

@NgModule({
  declarations: [JagaUpdateProfileComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        UpdateProfileComponent: {
          component: JagaUpdateProfileComponent,
          guards: [AuthGuard],
        },
      },
    } as CmsConfig),
    SpinnerModule,
    I18nModule,
    ReactiveFormsModule,
    FormErrorsModule,
    JagaCommonModule,
    CardModule,
  ],
})
export class JagaUpdateProfileModule {}
