import { Component } from '@angular/core';
import { Feature, ProductScope } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { JagaFeature, JagaProduct } from '../../../models/product.model';

@Component({
  selector: 'jaga-product-attributes-tab',
  templateUrl: './jaga-product-attributes-tab.component.html'
})
export class JagaProductAttributesTabComponent {
  product$: Observable<JagaProduct> = this.currentProductService.getProduct(
    ProductScope.ATTRIBUTES
  );

  constructor(protected currentProductService: CurrentProductService) {
  }

  convertToFeatureGroups(features: JagaFeature[]): Map<string, Feature[]> {
    const map = new Map();

    features.forEach(feature => {
      const key = feature.groupingId || 'fallback';
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [feature]);
      } else {
        collection.push(feature);
      }
    });

    return map;
  }
}

