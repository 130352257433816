import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { SmartEditRootModule, SMART_EDIT_FEATURE, SmartEditConfig } from '@spartacus/smartedit/root';
import { environment } from '../../../../environments/environment';

const smartEditConfig: SmartEditConfig = { smartEdit: {} };

if (environment.allowOrigin && smartEditConfig?.smartEdit) {
  smartEditConfig.smartEdit.allowOrigin = environment.allowOrigin;
}

@NgModule({
  declarations: [],
  imports: [SmartEditRootModule],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [SMART_EDIT_FEATURE]: {
          module: () => import('@spartacus/smartedit').then((m) => m.SmartEditModule),
        },
      },
    }),
    provideConfig(<SmartEditConfig>smartEditConfig),
  ],
})
export class SmartEditFeatureModule {}
