import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { JagaParagraphComponent } from './components/jaga-paragraph/jaga-paragraph.component';
import { JagaLinkComponent } from './components/jaga-link/jaga-link.component';
import { JagaCommonModule } from '../jaga-common/jaga-common.module';
import {
  GenericLinkModule,
  IconConfig,
  IconModule,
  IconResourceType,
  MediaModule,
  OutletModule,
  PageComponentModule
} from '@spartacus/storefront';
import { JagaTabParagraphContainerComponent } from './components/jaga-tab-paragraph-container/jaga-tab-paragraph-container.component';
import { JagaAccordionParagraphComponent } from './components/jaga-accordion-paragraph/jaga-accordion-paragraph.component';
import { JagaMapComponent } from './components/jaga-map/jaga-map.component';
import { JagaContactComponent } from './components/jaga-contact/jaga-contact.component';
import { JagaBannerComponent } from './components/jaga-banner/jaga-banner.component';
import { JagaEmailSubscribeComponent } from './components/jaga-email-subscribe/jaga-email-subscribe.component';

@NgModule({
  declarations: [
    JagaParagraphComponent,
    JagaLinkComponent,
    JagaTabParagraphContainerComponent,
    JagaAccordionParagraphComponent,
    JagaBannerComponent,
    JagaAccordionParagraphComponent,
    JagaMapComponent,
    JagaContactComponent,
    JagaEmailSubscribeComponent
  ],
  exports: [
    JagaParagraphComponent
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      icon: {
        symbols: {
          MAP: 'fas fa-map-marker-alt',
          PHONE: 'fas fa-phone',
          MAIL: 'far fa-envelope',
          TIME: 'fas fa-clock',
        },
        resources: [
          {
            type: IconResourceType.LINK,
            url: 'https://use.fontawesome.com/releases/v5.8.1/css/all.css',
          },
        ]
      }

    } as IconConfig),
    ConfigModule.withConfig({
      cmsComponents: {
        CMSParagraphComponent: {
          component: JagaParagraphComponent
        },
        CMSTabParagraphContainer: {
          component: JagaTabParagraphContainerComponent
        },
        CMSLinkComponent: {
          component: JagaLinkComponent
        },
        CMSAccordionParagraphComponent: {
          component: JagaAccordionParagraphComponent
        },
        BannerComponent: {
          component: JagaBannerComponent
        },
        SimpleResponsiveBannerComponent: {
          component: JagaBannerComponent
        },
        SimpleBannerComponent: {
          component: JagaBannerComponent
        },
        CMSMapComponent: {
          component: JagaMapComponent
        },
        CMSContactComponent: {
          component: JagaContactComponent
        },
        CMSEmailSubscribeComponent: {
         component: JagaEmailSubscribeComponent
        }
      },
      JagaCommonModule
    } as CmsConfig),
    JagaCommonModule,
    GenericLinkModule,
    I18nModule,
    OutletModule,
    PageComponentModule,
    IconModule,
    PageComponentModule,
    MediaModule
  ]
})
export class JagaContentModule {
}
