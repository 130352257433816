import {AfterViewChecked, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[jagaHideNoContent]'
})
export class HideNoContentDirective implements AfterViewChecked{

  static readonly NO_CONTENT = 'no-content';
  private el;
  @Input() jagaHideNoContent: boolean;

  constructor(private element: ElementRef)  {
    this.el = element;
  }

  ngAfterViewChecked(): void {
    if (this.jagaHideNoContent) {
      this.el.nativeElement.classList.add(HideNoContentDirective.NO_CONTENT);
    }
  }
}
