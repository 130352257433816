<h1 class="h1">{{ "loginForm.jaga-login" | cxTranslate }}</h1>
<p>{{ "loginForm.jaga-loginDisclaimer" | cxTranslate }}</p>
<form (ngSubmit)="submitForm()" [formGroup]="loginForm">
  <div class="form-group">
    <label>
      <span class="label-content">
        {{ "loginForm.emailAddress.label" | cxTranslate }}
      </span>
      <input
        type="email"
        class="form-control"
        formControlName="userId"
        placeholder="{{ 'loginForm.emailAddress.placeholder' | cxTranslate }}"
      />
      <cx-form-errors [control]="loginForm.get('userId')"></cx-form-errors>
    </label>
  </div>
  <div class="form-group">
    <label>
      <span class="label-content">
        {{ "loginForm.password.label" | cxTranslate }}
      </span>
      <input
        type="password"
        class="form-control"
        placeholder="{{ 'loginForm.password.placeholder' | cxTranslate }}"
        formControlName="password"
      />
      <cx-form-errors [control]="loginForm.get('password')"></cx-form-errors>
    </label>
  </div>
  <p>
    <a
      [routerLink]="{ cxRoute: 'forgotPassword' } | cxUrl"
      aria-controls="reset-password"
      class="btn-link"
    >
      {{ "loginForm.forgotPassword" | cxTranslate }}
    </a>
  </p>

  <button type="submit" class="btn btn-block btn-primary">
    {{ "loginForm.signIn" | cxTranslate }}
  </button>
</form>
