import { ChangeDetectionStrategy, Component } from '@angular/core';
import { JagaProductBaseItemComponent } from '../jaga-product-base-item/jaga-product-base-item.component';

@Component({
  selector: 'jaga-product-list-item',
  templateUrl: './jaga-product-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JagaProductListItemComponent extends JagaProductBaseItemComponent {
}
