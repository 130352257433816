import { Injectable } from '@angular/core';
import {
  BaseSiteService,
  JavaRegExpConverter,
  SiteContextConfigInitializer,
  WindowRef,
} from '@spartacus/core';
import { JagaBaseSite } from '../../jaga-model/jaga-misc.model';
import { GTAG_ID, JagaSiteContextConfig } from './jaga-occ-loaded.config';

@Injectable({ providedIn: 'root' })
export class JagaSiteContextConfigInitializer extends SiteContextConfigInitializer {
  constructor(
    protected baseSiteService: BaseSiteService,
    protected javaRegExpConverter: JavaRegExpConverter,
    protected winRef: WindowRef
  ) {
    super(baseSiteService, javaRegExpConverter, winRef);
  }

  protected getConfig(source: JagaBaseSite): JagaSiteContextConfig {
    const siteContextConfig = super.getConfig(source);
    let gtm = source.gtm;
    if (!source.gtm) {
      gtm = 'GTM_NOT_SET';
    }

    return {
      context: {
        ...siteContextConfig.context,
        [GTAG_ID]: [gtm],
      },
    };
  }
}
