<ng-container *ngFor="let component of components$ | async; let i = index">
  <ng-container *ngIf="component">
    <div jagaCheckNoContent>
      <button (click)="select(i)" [class.active]="activeTabs.includes(i)">
        {{ component.title | cxTranslate: {param: tabTitleParams[i] | async} }}
      </button>
      <div [class.active]="activeTabs.includes(i)">
        <ng-template [cxOutlet]="component.flexType" [cxOutletContext]="{}">
          <ng-container [cxComponentWrapper]="component"></ng-container>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-container>


