import { APP_INITIALIZER, NgModule, Optional } from '@angular/core';
import { I18nextInit } from './i18next-init';
import { ConfigInitializerService, LanguageService, SERVER_REQUEST_ORIGIN, TranslationService } from '@spartacus/core';
import { HttpClient } from '@angular/common/http';
import { JagaTranslationService } from './jaga-translation.service';


@NgModule({
  providers: [
    {
      provide: TranslationService,
      useClass: JagaTranslationService
    },
    {
      provide: APP_INITIALIZER,
      useFactory: I18nextInit,
      deps: [
        ConfigInitializerService,
        LanguageService,
        HttpClient,
        [new Optional(), SERVER_REQUEST_ORIGIN]],
      multi: true
    }
  ]
})
export class JagaTranslationModule {
}
