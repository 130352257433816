<cx-spinner class="overlay" *ngIf="isUpdating$ | async"></cx-spinner>
<ng-container *ngIf="user$ | async as user">
  <form [formGroup]="form" *ngIf="user?.uid">
    <div class="row d-flex">
      <div class="col-12 col-md-6">
        <h1>{{ "updateProfileForm.jaga-personalInfo" | cxTranslate }}</h1>
        <label>
          <span class="label-content">{{
            "updateProfileForm.title" | cxTranslate
          }}</span>
          <!-- Replace with select if form has to be enabled -->
          <input
            [value]="user?.title"
            class="form-control"
            disabled
            type="text"
          />
        </label>

        <label>
          <span class="label-content">
            {{ "updateProfileForm.firstName.label" | cxTranslate }}
          </span>
          <input
            class="form-control"
            formControlName="firstName"
            name="firstName"
            placeholder="{{
              'updateProfileForm.firstName.placeholder' | cxTranslate
            }}"
            type="text"
            disabled
          />
          <cx-form-errors [control]="form.get('firstName')"> </cx-form-errors>
        </label>

        <label>
          <span class="label-content">
            {{ "updateProfileForm.lastName.label" | cxTranslate }}
          </span>
          <input
            class="form-control"
            formControlName="lastName"
            name="lastName"
            placeholder="{{
              'updateProfileForm.lastName.placeholder' | cxTranslate
            }}"
            type="text"
            disabled
          />
          <cx-form-errors [control]="form.get('lastName')"> </cx-form-errors>
        </label>
      </div>

      <div class="col-12 col-md-6">
        <h1>{{ "updateProfileForm.jaga-companyInfo" | cxTranslate }}</h1>

        <label>
          <span class="label-content">
            {{ "updateProfileForm.jaga-companyName.label" | cxTranslate }}
          </span>
          <input
            [value]="(user && user['orgUnit']?.name) || ' '"
            class="form-control"
            disabled
            placeholder="{{
              'updateProfileForm.jaga-companyName.placeholder' | cxTranslate
            }}"
            type="text"
          />
        </label>

        <label>
          <span class="label-content">
            {{ "updateProfileForm.jaga-customerNumber.label" | cxTranslate }}
          </span>
          <input
            [value]="(user && user['orgUnit']?.uid) || ' '"
            class="form-control"
            disabled
            placeholder="{{
              'updateProfileForm.jaga-customerNumber.placeholder' | cxTranslate
            }}"
            type="text"
          />
        </label>

        <label>
          <span class="label-content">
            {{ "updateProfileForm.jaga-vatNumber.label" | cxTranslate }}
          </span>
          <input
            [value]="(user && user['orgUnit']?.vatID) || ' '"
            class="form-control"
            disabled
            placeholder="{{
              'updateProfileForm.jaga-vatNumber.placeholder' | cxTranslate
            }}"
            type="text"
          />
        </label>
      </div>
    </div>
  </form>
</ng-container>
