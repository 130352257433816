<ng-container *ngFor="let child of node?.children">
  <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: 0 }">
  </ng-container>
</ng-container>

<ng-template #nav let-node="node" let-depth="depth">
  <nav
    #navigation
    (click)="toggleOpen($event)"
    (mouseenter)="onMouseEnter($event)"
    (keydown.space)="toggleOpen($event)"
    (keydown.esc)="back()"
  >
    <cx-generic-link
      *ngIf="
        node.url && (!node.children || node.children?.length === 0);
        else heading
      "
      [url]="node.url"
      [target]="node.target"
      [style]="node.styleAttributes"
      [class]="node.styleClasses"
    >
      {{ node.title }}
      <cx-icon
        *ngIf="flyout && node.children?.length > 0"
        [type]="iconType.CARET_DOWN"
      ></cx-icon>
    </cx-generic-link>

    <ng-template #heading>
      <span [attr.tabindex]="flyout && (depth === 0 || node.url) ? 0 : -1">
        {{ node.title }}
        <cx-icon
          *ngIf="flyout && node.children?.length > 0"
          [type]="iconType.CARET_DOWN"
        ></cx-icon>
      </span>
    </ng-template>

    <div class="wrapper" *ngIf="node.children?.length > 0">
      <div *ngIf="user$ | async as user" class="account-details">
        <div class="user">
          <cx-icon type="USER"></cx-icon>
          <span>{{ user.name }}</span>
        </div>
        <div class="org">
          <cx-icon type="BRIEFCASE"></cx-icon>
          <span>{{ user.orgUnit.name }}</span>
        </div>
      </div>
      <div
        class="childs"
        [attr.depth]="getTotalDepth(node)"
        [attr.wrap-after]="node.children?.length > wrapAfter ? wrapAfter : null"
        [attr.columns]="getColumnCount(node.children?.length)"
      >
        <ng-container *ngFor="let child of node.children">
          <ng-container
            *ngTemplateOutlet="nav; context: { node: child, depth: depth + 1 }"
          >
          </ng-container>
        </ng-container>
      </div>
    </div>
  </nav>
</ng-template>
