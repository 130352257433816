import { Injectable } from '@angular/core';
import {
  ConverterService,
  OccEndpointsService,
  OccSiteAdapter,
} from '@spartacus/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JagaBaseSite } from '../../jaga-model/jaga-misc.model';

@Injectable({
  providedIn: 'root',
})
export class JagaOccSiteAdapter extends OccSiteAdapter {
  constructor(
    http: HttpClient,
    occEndpointsService: OccEndpointsService,
    converterService: ConverterService
  ) {
    super(http, occEndpointsService, converterService);
  }

  loadBaseSites(): Observable<JagaBaseSite[]> {
    const baseUrl = this.occEndpointsService.getBaseUrl();
    const urlSplits = baseUrl.split('/');
    const activeSite = urlSplits.pop();
    const url = urlSplits.join('/') + '/basesites';

    const params = new HttpParams({
      fromString: 'fields=DEFAULT',
    });

    return this.http.get<{ baseSites: JagaBaseSite[] }>(url, { params }).pipe(
      map((siteList) => {
        return siteList.baseSites.map((site: JagaBaseSite) => {
          site.selected = site.uid === activeSite;
          return site;
        });
      })
    );
  }
}
