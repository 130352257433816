import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {
  ActiveCartService,
  StateWithMultiCart,
  UserIdService,
} from '@spartacus/core';
import { JagaMultiCartService } from './jaga-multi-cart.service';
import { JagaCart } from '../../jaga-checkout/core/models/jaga-cart.model';

@Injectable({
  providedIn: 'root',
})
export class JagaActiveCartService
  extends ActiveCartService
  implements OnDestroy
{
  protected subscription = new Subscription();
  protected activeCart$: Observable<JagaCart>;

  constructor(
    protected store: Store<StateWithMultiCart>,
    protected multiCartService: JagaMultiCartService,
    protected userIdService: UserIdService
  ) {
    super(store, multiCartService, userIdService);
  }

  getActive(): Observable<JagaCart> {
    return this.activeCart$;
  }
}
