import {AfterViewChecked,  Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[jagaCheckNoContent]'
})
export class CheckNoContentDirective implements AfterViewChecked  {

  private el: ElementRef;

  constructor(private element: ElementRef) {
    this.el = element;
  }

  ngAfterViewChecked(): void {
   if (this.el.nativeElement.querySelector('.no-content')) {
        this.el.nativeElement.classList.add('d-none');
   }
  }
}
