import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Order } from '@spartacus/core';
import { OrderDetailsService } from '@spartacus/order/components';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cx-order-details-headline',
  templateUrl: './jaga-order-detail-headline.component.html',
})
export class JagaOrderDetailHeadlineComponent implements OnInit {
  constructor(private orderDetailsService: OrderDetailsService) {}

  order$: Observable<Order>;

  ngOnInit() {
    this.order$ = this.orderDetailsService.getOrderDetails();
  }
}
