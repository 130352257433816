import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CartTotalsComponent } from '@spartacus/storefront';
import { ActiveCartService } from '@spartacus/core';
import { Location } from '@angular/common';

@Component({
  selector: 'jaga-cart-totals',
  templateUrl: './jaga-cart-totals.component.html',
  styles: [
    `
      :host {
        padding: 0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JagaCartTotalsComponent extends CartTotalsComponent {
  constructor(
    activeCartService: ActiveCartService,
    private location: Location
  ) {
    super(activeCartService);
  }

  backClicked() {
    this.location.back();
  }
}
