<ng-container *ngIf="component.data$ | async as data">
  <button (click)="showContent = !showContent"
          [class.active]="showContent"
          [attr.id]="data.anchor"
          [innerHTML]="data.title"
          class="title"></button>
  <div *ngIf="showContent" class="content">
    <jaga-paragraph></jaga-paragraph>
  </div>
</ng-container>
