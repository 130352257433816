import { Component, OnInit } from '@angular/core';
import {
  OrderDetailShippingComponent,
  OrderDetailsService,
} from '@spartacus/storefront';
import { Address, Occ, PaymentType, TranslationService } from '@spartacus/core';
import { JagaOrder } from '../../../../../jaga-model/jaga-order.model';
import { Observable } from 'rxjs';
import { Card } from '@spartacus/storefront/shared/components/card/card.component';
import { filter, map } from 'rxjs/operators';
import DeliveryMode = Occ.DeliveryMode;

@Component({
  selector: 'jaga-order-detail-shipping',
  templateUrl: './jaga-order-detail-shipping.component.html',
})
export class JagaOrderDetailShippingComponent
  extends OrderDetailShippingComponent
  implements OnInit
{
  order$: Observable<JagaOrder>;

  constructor(
    private jagaOrderDetailsService: OrderDetailsService,
    private translationService: TranslationService
  ) {
    super(jagaOrderDetailsService);
  }

  ngOnInit() {
    this.order$ = this.jagaOrderDetailsService.getOrderDetails();
  }

  getPaymentTypeCardContent(paymentType: PaymentType): Observable<Card> {
    return this.translationService.translate('paymentTypes.title').pipe(
      filter(() => Boolean(paymentType)),
      map((title) => ({
        title,
        text: [paymentType.displayName],
      }))
    );
  }

  getShippingMethodCardContent(shipping: DeliveryMode): Observable<Card> {
    return this.translationService
      .translate('checkoutShipping.shippingMethod')
      .pipe(
        map((title) => {
          return {
            title,
            text: [shipping.name],
          };
        })
      );
  }

  getAddressCardContent(address: Address): Observable<Card> {
    return this.translationService.translate('addressCard.shipTo').pipe(
      map((textTitle) => {
        return this.getFormattedAddress(address, textTitle);
      })
    );
  }

  getBillingAddressCardContent(billingAddress: Address): Observable<Card> {
    return this.translationService.translate('addressCard.billTo').pipe(
      map((textTitle) => {
        return this.getFormattedAddress(billingAddress, textTitle);
      })
    );
  }

  getFormattedAddress(address: Address, title: string): Card {
    let region = '';
    if (address.region && address.region.name) {
      region = `${address.region.name}, `;
    }

    let name = '';
    if (address.firstName && address.lastName) {
      name = `${address.firstName} ${address.lastName}`;
    }

    return {
      title,
      textBold: '',
      text: [
        name,
        address.companyName,
        address.line1,
        `${address.postalCode} ${address.town}`,
        region + address.country.name,
        address.phone,
      ],
    };
  }
}
