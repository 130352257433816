<div class="jaga-product-list-item">
  <div class="row">
    <div class="col-12 col-md-4">
      <a
        [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
        class="cx-product-image-container"
        tabindex="-1"
      >
        <cx-media
          [alt]="product.summary"
          [container]="product.images?.PRIMARY"
          class="cx-product-image"
        ></cx-media>
      </a>
    </div>
    <div class="col-12 col-md-8">
      <a
        [innerHTML]="product.groupDescription"
        [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
        class="cx-product-group-description">
      </a>
      <a
        [innerHtml]="product.nameHtml"
        [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
        class="cx-product-name">
      </a>

      <a [class.jaga-hybrid-assignments]="product?.coolingAssignments && product?.heatingAssignments"
         [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
         class="jaga-product-assignments">
        <ul *ngIf="product?.coolingAssignments"
            class="jaga-cooling-assignments">
          <li *ngFor="let assignment of product?.coolingAssignments"
              [innerHTML]="assignment"></li>
        </ul>

        <ul *ngIf="product?.heatingAssignments"
            class="jaga-heating-assignments">
          <li
            *ngFor="let assignment of product?.heatingAssignments"
            [innerHTML]="assignment"></li>
        </ul>
      </a>

      <div
        *ngIf="isAuthenticated | async"
        aria-label="Product price" class="cx-product-price">
        {{ product.price?.formattedValue }}
      </div>

      <cx-variant-style-icons
        *ngIf="product.variantOptions"
        [variants]="product.variantOptions"
      ></cx-variant-style-icons>

      <div class="row">
        <div class="col-12">
          <p [innerHtml]="product.summary" class="cx-product-summary">
            {{ product.summary }}
          </p>
        </div>
        <div class="col-12">
          <jaga-add-to-cart
            *ngIf="product.stock?.stockLevelStatus !== 'outOfStock' && isAuthenticated | async"
            [product]="product"
            [showQuantity]="true">
          </jaga-add-to-cart>
        </div>
      </div>
    </div>
  </div>
</div>
