<div>
  <cx-icon type="MAP"></cx-icon>&nbsp;
  {{ 'contact.address' | cxTranslate }}
</div>
<br>
<div>
  <cx-icon type="PHONE"></cx-icon>&nbsp;
  <a href='tel:+3211294111'>+32 (0) 11 29 41 11</a>
</div>
<br>
<div>
  <cx-icon type="MAIL"></cx-icon>&nbsp;
  <a href='mailto:info@jaga.com'>info@jaga.com</a>
</div>
<br>
<div>
  <cx-icon type="TIME"></cx-icon>&nbsp;
  {{ 'contact.openingHours' | cxTranslate }}
</div>
