import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StateModule } from '@spartacus/core';
import { EffectsModule } from '@ngrx/effects';
import { effects } from './effects';
import { StoreModule } from '@ngrx/store';
import { QUICK_ORDER_FEATURE } from './quick-order-state';
import { reducerProvider, reducerToken } from './reducers';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StateModule,
    EffectsModule.forFeature(effects),
    StoreModule.forFeature(QUICK_ORDER_FEATURE, reducerToken),
  ],
  providers: [
    reducerProvider
  ]
})
export class QuickOrderStoreModule {
}
