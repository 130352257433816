import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OccEndpointsService } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class JagaUserService {
  protected headers = new HttpHeaders().set('Content-Type', 'application/json');
  private ORG_UNITS_ENDPOINT = 'orgUnits/validate';
  private ORG_UNITS_EMAIL_ENDPOINT = 'user/validate/email';
  private ORG_UNITS_VAT_ID_CUSTOMER_NUMBER_ENDPOINT =
    this.ORG_UNITS_ENDPOINT + '/combination';
  private ORG_UNITS_POSTAL_CODE_ENDPOINT = '/address/validate/postcode';

  constructor(
    private http: HttpClient,
    private occEndpoints: OccEndpointsService
  ) {}

  public validateVATNumber(vatID: string): Observable<boolean> {
    return this.http.post<boolean>(
      this.occEndpoints.buildUrl(this.ORG_UNITS_ENDPOINT),
      { vatID: vatID.toUpperCase().trim() }
    );
  }

  public checkIfEmailExists(email: string): Observable<boolean> {
    return this.http.post<boolean>(
      this.occEndpoints.buildUrl(this.ORG_UNITS_EMAIL_ENDPOINT),
      { email: email.toLowerCase() }
    );
  }

  public validateVATNumberCustomerNumber(
    vatID: string,
    customerNumber: string
  ): Observable<boolean> {
    return this.http.post<boolean>(
      this.occEndpoints.buildUrl(
        this.ORG_UNITS_VAT_ID_CUSTOMER_NUMBER_ENDPOINT
      ),
      {
        vatID: vatID.toUpperCase().trim(),
        customerNumber: customerNumber.trim(),
      }
    );
  }

  public validatePostalCode(companyAddressPostalCode: string) {
    return this.http.post<boolean>(
      this.occEndpoints.buildUrl(this.ORG_UNITS_POSTAL_CODE_ENDPOINT),
      {
        companyAddressPostalCode,
      }
    );
  }
}
