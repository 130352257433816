import { Injectable } from '@angular/core';
import { Converter, Occ } from '@spartacus/core';
import { JagaProduct } from '../../jaga-product/models/product.model';

@Injectable({
  providedIn: 'root'
})
export class ProductNameNormalizer implements Converter<Occ.Product, JagaProduct> {

  constructor() {
  }

  convert(source: Occ.Product, target?: JagaProduct): JagaProduct {
    if (source.name) {
      target.seoName = source.name.replace(/ /g, '-').toLowerCase();
    }
    return target;
  }
}
