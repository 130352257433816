<cx-spinner class="overlay" *ngIf="isUpdating$ | async"></cx-spinner>
<form
  (ngSubmit)="onSubmit()"
  [formGroup]="form"
  class="cx-update-password-component"
>
  <label>
    <span class="label-content">{{
      "updatePasswordForm.oldPassword.label" | cxTranslate
    }}</span>
    <jaga-toggle-password>
      <input
        aria-required="true"
        class="form-control"
        formControlName="oldPassword"
        name="oldPassword"
        placeholder="{{
          'updatePasswordForm.oldPassword.placeholder' | cxTranslate
        }}"
        type="password"
      />
    </jaga-toggle-password>
    <cx-form-errors [control]="form.get('oldPassword')"></cx-form-errors>
  </label>

  <label>
    <span class="label-content">{{
      "updatePasswordForm.newPassword.label" | cxTranslate
    }}</span>
    <jaga-toggle-password>
      <input
        class="form-control"
        formControlName="newPassword"
        name="newPassword"
        placeholder="{{
          'updatePasswordForm.newPassword.placeholder' | cxTranslate
        }}"
        type="password"
      />
    </jaga-toggle-password>
    <cx-form-errors [control]="form.get('newPassword')"></cx-form-errors>
  </label>

  <label>
    <span class="label-content">{{
      "updatePasswordForm.confirmPassword.label" | cxTranslate
    }}</span>
    <jaga-toggle-password>
      <input
        class="form-control"
        formControlName="newPasswordConfirm"
        name="newPasswordConfirm"
        placeholder="{{
          'updatePasswordForm.confirmPassword.placeholder' | cxTranslate
        }}"
        type="password"
      />
    </jaga-toggle-password>
  </label>

  <div class="form-group row">
    <div class="col-lg-6 col-md-12">
      <button
        (click)="onCancel()"
        class="btn btn-block btn-secondary"
        type="button"
      >
        {{ "common.cancel" | cxTranslate }}
      </button>
    </div>
    <div class="col-lg-6 col-md-12">
      <button class="btn btn-block btn-primary" type="submit">
        {{ "common.save" | cxTranslate }}
      </button>
    </div>
  </div>
</form>
