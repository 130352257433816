<ng-container *ngIf="component.data$ | async as data">
  <div class="jaga-email-subscribe">
    <div class="title">
      {{ data.title }}
    </div>

    <div class="button-wrapper">
      <a [href]="data.url" class="btn btn-block btn-cta-solid" target="_blank">{{data.buttonText}}</a>
    </div>
  </div>
</ng-container>
