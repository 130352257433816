import { Component, OnChanges, ViewChild } from '@angular/core';
import { MediaComponent, MediaService } from '@spartacus/storefront';
import { PinchZoomComponent } from '@olafvv/ngx-pinch-zoom';

@Component({
  selector: 'jaga-media-zoom',
  templateUrl: './jaga-media-zoom.component.html',
})
export class JagaMediaZoomComponent
  extends MediaComponent
  implements OnChanges
{
  @ViewChild('pinchZoom')
  pinchZoom: PinchZoomComponent;

  constructor(mediaService: MediaService) {
    super(mediaService);
  }

  ngOnChanges() {
    super.ngOnChanges();
    if (this.pinchZoom?.isZoomedIn) {
      this.toggleZoom();
    }
  }

  toggleZoom() {
    this.pinchZoom.toggleZoom();
  }
}
