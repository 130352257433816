import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationComponent } from '@spartacus/storefront';

@Component({
  selector: 'jaga-acc-nav',
  templateUrl: 'jaga-acc-nav.component.html',
  styleUrls: ['./jaga-acc-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JagaAccNavComponent extends NavigationComponent {}
