import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'jaga-line-separator',
  templateUrl: './jaga-line-separator.component.html'
})
export class JagaLineSeparatorComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
