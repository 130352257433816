import { Component } from '@angular/core';
import {
  OrderDetailItemsComponent,
  OrderDetailsService,
} from '@spartacus/order/components';

@Component({
  selector: 'jaga-order-detail-items',
  templateUrl: './jaga-order-detail-items.component.html',
})
export class JagaOrderDetailItemsComponent extends OrderDetailItemsComponent {
  constructor(orderDetailsService: OrderDetailsService) {
    super(orderDetailsService);
  }
}
