import { MollieCheckoutActions } from '../actions';
import {
  MollieConfirmPlaceOrder,
  MollieCreateOrder,
  MollieCreateOrderSuccess,
} from '../actions/mollie-checkout.action';
import { MollieCheckoutConnector } from '../../connectors/checkout';
import { normalizeHttpError } from '../../util/normalize-http-error';
import { CheckoutActions } from '@spartacus/checkout/core';
import { Observable, of } from 'rxjs';
import { CartActions, RoutingService, WindowRef } from '@spartacus/core';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

// @ts-ignore
@Injectable()
export class MollieCheckoutEffects {
  createOrder$: Observable<
    | MollieCheckoutActions.MollieCreateOrderSuccess
    | MollieCheckoutActions.MollieCreateOrderFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(MollieCheckoutActions.MOLLIE_CREATE_ORDER),
      map((action: MollieCreateOrder) => action.payload),
      switchMap((payload) => {
        return this.connector
          .createOrder(payload.userId, payload.cartId, payload.termsChecked)
          .pipe(
            map((paymentUrl) => {
              if (paymentUrl) {
                return new MollieCheckoutActions.MollieCreateOrderSuccess(
                  paymentUrl
                );
              } else {
                return new MollieCheckoutActions.MollieCreateOrderFail(null);
              }
            }),
            catchError((error) =>
              of(
                new MollieCheckoutActions.MollieCreateOrderFail(
                  normalizeHttpError(error)
                )
              )
            )
          );
      })
    )
  );

  createOrderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MollieCheckoutActions.MOLLIE_CREATE_ORDER_SUCCESS),
        map((action: MollieCreateOrderSuccess) => action.payload),
        tap((redirectUrl) => {
          const nativeWindow = this.winRef.nativeWindow;
          if (nativeWindow) {
            nativeWindow.location.href = redirectUrl;
          }
        })
      ),
    { dispatch: false }
  );

  confirmPlaceOrder$: Observable<
    CheckoutActions.PlaceOrderSuccess | CartActions.RemoveCart
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(MollieCheckoutActions.MOLLIE_CONFIRM_PLACE_ORDER),
      map((action: MollieConfirmPlaceOrder) => action.payload),
      switchMap((payload) => {
        return [
          new CartActions.RemoveCart({ cartId: payload.code }),
          new CheckoutActions.PlaceOrderSuccess(payload),
        ];
      })
    )
  );

  constructor(
    private actions$: Actions,
    private winRef: WindowRef,
    private connector: MollieCheckoutConnector
  ) {}
}
