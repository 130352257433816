import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'jaga-toggle-password',
  templateUrl: './jaga-toggle-password.component.html',
  styleUrls: ['./jaga-toggle-password.component.scss']
})
export class JagaTogglePasswordComponent implements OnInit {

  public input: any;

  public isHidden: boolean;

  constructor(private elem: ElementRef,
              private renderer: Renderer2) {
  }

  private readonly INPUT_TYPE_PASSWORD = 'password';
  private readonly INPUT_TYPE_TEXT = 'text';

  ngOnInit(): void {
    this.input = this.elem.nativeElement.querySelector('input');
    if (!this.input) {
      throw new Error(`No input element found.`);
    }

    // add padding to accommodate show/hide button
    this.input.style.paddingRight = '44px';
    this.isHidden = this.input.type === this.INPUT_TYPE_PASSWORD;
  }

  togglePasswordVisibility() {
    this.renderer.setAttribute(this.input, 'type', this.isHidden ? this.INPUT_TYPE_TEXT : this.INPUT_TYPE_PASSWORD);
    this.isHidden = !this.isHidden;
  }
}
