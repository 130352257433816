import { LayoutConfig } from '@spartacus/storefront';

const layoutSlots = {
  top: {
    lg: {
      slots: [
        'JagaContactInfo',
        'JagaMarketingInfo',
        'SiteContext'
      ]
    },
    slots: [
      'JagaContactInfo'
    ]
  },
  header: {
    lg: {
      slots: [
        'SiteLogo',
        'SearchBox',
        'SiteLinks',
        'SiteLogin',
        'MiniCart',
      ],
    },
    slots: ['SiteLogo', 'SearchBox', 'SiteLogin', 'MiniCart', 'PreHeader'],
  },
  jagaNavigation: {
    lg: { slots: ['NavigationBar'] },
    slots: [],
  },
  navigation: {
    lg: { slots: [] },
    slots: ['NavigationBar', 'SiteContext', 'SiteLinks'],
  },
  footer: {
    slots: [
      'TopFooter'
    ],
  },
  bottom: {
    slots: [
      'Footer',
      'FooterContactInfo',
      'FooterAdditionalInfo',
      'FooterLogo',
      'FooterAddressInfo',
      'FooterSiteContext',
      'FooterSocialMedia',
      'FooterLineSeparator',
      'FooterCopyright',
      'FooterLinks'
    ]
  },
  LandingPage2Template: {
    pageFold: 'Section2',
    slots: [
      'Section1',
      'Section2',
      'Section3A',
      'Section3B',
      'Section4A',
      'Section4B',
      'Section5A',
      'Section5B',
      'Section6A',
      'Section6B',
      'Section6C',
      'Section7',
      'Section8A',
      'Section8B',
      'Section8C',
      'Section8D',
      'Section9',
      'Section10A',
      'Section10B',
      'Section11A',
      'Section11B',
      'Section12',
      'Section13',
      'Section14A',
      'Section14B',
      'Section14C',
      'Section15A',
      'Section15B',
      'Section15C',
      'Section15D',
      'Section16',
      'Section17',
      'Section18A',
      'Section18B',
      'Section18C',
      'Section18D',
    ],
  },
  LandingPage3Template: {
    pageFold: 'Section2',
    slots: [
      'Section1',
      'Section2',
      'Section3A',
      'Section3B',
      'Section4A',
      'Section4B',
      'Section5A',
      'Section5B',
      'Section6A',
      'Section6B',
      'Section6C',
      'Section7',
      'Section8A',
      'Section8B',
      'Section8C',
      'Section9',
      'Section10',
      'Section11',
      'Section12',
      'Section13A',
      'Section13B',
      'Section14A',
      'Section14B',
      'Section14C',
      'Section15A',
      'Section15B',
      'Section15C',
      'Section15D',
      'Section16',
      'Section17',
      'Section18A',
      'Section18B',
      'Section18C',
      'Section18D',
    ],
  },
  ContentPage1Template: {
    slots: [
      'Section1',
      'Section2',
      'Section3A',
      'Section3B',
      'Section4A',
      'Section4B',
      'Section5A',
      'Section5B',
      'Section6A',
      'Section6B',
      'Section6C',
      'Section7A',
      'Section7B',
      'Section7C',
      'Section7D',
      'Section8',
      'Section9A',
      'Section9B',
      'Section10A',
      'Section10B',
      'Section11A',
      'Section11B',
      'Section12A',
      'Section12B',
      'Section12C',
      'Section13A',
      'Section13B',
      'Section13C',
      'Section13D',
      'Section14',
    ],
  },
  ContentPage2Template: {
    slots: [
      'Section1',
      'Section2',
      'Section3',
      'Section4A',
      'Section4B',
      'Section5A',
      'Section5B',
      'Section6A',
      'Section6B',
      'Section7',
      'Section8',
      'Section9',
    ],
  },
  ContentPage3Template: {
    slots: [
      'Section1',
      'Section2',
      'Section3A',
      'Section3B',
      'Section4A',
      'Section4B',
      'Section4C',
      'Section4D',
      'Section5',
      'Section6A',
      'Section6B',
      'Section7A',
      'Section7B',
      'Section7C',
      'Section7D',
      'Section8',
      'Section9',
    ],
  },
  ContentPage4Template: {
    slots: [
      'Section1',
      'Section2',
      'Section3A',
      'Section3B',
      'Section4A',
      'Section4B',
      'Section5A',
      'Section5B',
      'Section6A',
      'Section6B',
      'Section6C',
      'Section7A',
      'Section7B',
      'Section7C',
      'Section7D',
      'Section8',
      'Section9A',
      'Section9B',
      'Section10A',
      'Section10B',
      'Section11A',
      'Section11B',
      'Section12A',
      'Section12B',
      'Section12C',
      'Section13A',
      'Section13B',
      'Section13C',
      'Section13D',
      'Section14',
    ],
  },
  ContentPage5Template: {
    slots: [
      'Section1',
      'Section2',
      'Section3',
      'Section4A',
      'Section4B',
      'Section4C',
      'Section5A',
      'Section5B',
      'Section5C',
      'Section6A',
      'Section6B',
      'Section6C',
      'Section7A',
      'Section7B',
      'Section7C',
      'Section7D',
      'Section8A',
      'Section8B',
      'Section9',
      'Section10',
    ],
  },
  ContentPage6Template: {
    slots: [
      'Section1',
      'Section2',
      'Section3',
      'Section4A',
      'Section4B',
      'Section4C',
      'Section5A',
      'Section5B',
      'Section5C',
      'Section6A',
      'Section6B',
      'Section6C',
      'Section7A',
      'Section7B',
      'Section7C',
      'Section7D',
      'Section8A',
      'Section8B',
      'Section8C',
      'Section8D',
      'Section9',
      'Section10',
    ],
  },
  CategoryPageTemplate: {
    pageFold: 'Section2',
    slots: ['Section1', 'Section2', 'Section3'],
  },
  ProductListPageTemplate: {
    slots: ['ProductLeftRefinements', 'ProductListSlot'],
  },
  ProductGridPageTemplate: {
    slots: ['ProductLeftRefinements', 'ProductGridSlot'],
  },
  SearchResultsEmptyPageTemplate: {
    slots: [
      'TopContent',
      'MiddleContent',
      'SideContent',
    ]
  },
  SearchResultsListPageTemplate: {
    slots: [
      'Section2',
      'ProductLeftRefinements',
      'SearchResultsListSlot',
      'Section4',
    ],
  },
  SearchResultsGridPageTemplate: {
    slots: [
      'Section2',
      'ProductLeftRefinements',
      'SearchResultsGridSlot',
      'Section4',
    ],
  },
  ProductDetailsPageTemplate: {
    lg: {
      pageFold: 'Details',
    },

    pageFold: 'Summary',

    slots: [
      'Summary',
      'Details',
      'Tabs',
      'MandatoryCrossSelling',
      'CrossSelling',
      'UpSelling',
      'PlaceholderContentSlot',
    ],
  },
  CartPageTemplate: {
    slots: ['TopContent', 'CenterRightContentSlot', 'EmptyCartMiddleContent'],
  },
  AccountPageTemplate: {
    slots: ['BodyContent', 'SideContent'],
  },
  LoginPageTemplate: {
    slots: ['HeaderContentSlot', 'LeftContentSlot', 'RightContentSlot'],
  },
  ErrorPageTemplate: {
    slots: ['TopContent', 'MiddleContent', 'BottomContent'],
  },
  OrderConfirmationPageTemplate: {
    slots: ['BodyContent', 'SideContent'],
  },
  MultiStepCheckoutSummaryPageTemplate: {
    slots: ['TopContent', 'BodyContent', 'SideContent', 'BottomContent'],
  },
  CheckoutLoginPageTemplate: {
    slots: ['RightContentSlot'],
  },
};
export const jagaLayoutConfig: LayoutConfig = { layoutSlots };
