import { Observable } from 'rxjs';

export abstract class MollieCheckoutAdapter {
  /**
   * Abstract method used to create a Mollie payment request.
   *
   * @param userId The `userId` for given user
   * @param cartId The `cartId` for cart used for placing order
   * @param termsChecked The `boolean value` whether the terms were accepted or not
   *
   * @return the Mollie payment url to redirect to
   */
  abstract createOrder(userId: string, cartId: string, termsChecked: boolean): Observable<string>;

}
