import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { JagaProduct } from '../../models/product.model';
import { JagaCurrentProductService } from '../../jaga-current-product.service';

@Component({
  selector: 'jaga-product-delivery-time-description',
  templateUrl: './jaga-product-delivery-time-description.component.html'
})
export class JagaProductDeliveryTimeDescriptionComponent {

  product$: Observable<JagaProduct> = this.currentProductService.getProduct();

  constructor(private currentProductService: JagaCurrentProductService) {
  }

}
