<div *ngIf="hasHeader" class="d-none d-md-block">
  <div class="cx-item-list-header row">
    <div class="cx-item-list-desc col-md-4 col-lg-5 col-xl-6">
      {{ 'cartItems.description' | cxTranslate }}
    </div>
    <div class="cx-item-list-price col-md-3 col-lg-2 col-xl-2">
      {{ 'cartItems.itemPrice' | cxTranslate }}
    </div>
    <div class="cx-item-list-qty col-md-2 col-lg-3 col-xl-2">
      {{ 'cartItems.quantity' | cxTranslate }}
    </div>

    <ng-container *ngIf="options.isSaveForLater; else totalHeader">
      <div class="cx-item-list-total col-md-2 col-lg-2 col-xl-2">
        {{ 'saveForLaterItems.stock' | cxTranslate }}
      </div>
    </ng-container>
    <div class="cx-item-list-total col-md-2 col-lg-2 col-xl-2">

    </div>
  </div>
</div>

<div *ngFor="let item of items; let i = index" class="cx-item-list-row">
  <div
    class="cx-item-list-items"
    *ngIf="getControl(item) | async as control"
    [class.is-changed]="control.get('quantity').dirty"
  >
    <jaga-cart-item
      [item]="item"
      [quantityControl]="control.get('quantity')"
      [readonly]="readonly"
      [promotionLocation]="promotionLocation"
      [options]="options"
    >
    </jaga-cart-item>
  </div>
</div>

<ng-template #totalHeader>
  <div class="cx-item-list-total col-md-2 col-lg-2 col-xl-2 pr-3">
    {{ 'cartItems.total' | cxTranslate }}
  </div>
</ng-template>
