import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CardModule, PromotionsModule } from '@spartacus/storefront';
import { JagaOrderConfirmationOverviewComponent } from './components/jaga-order-confirmation-overview/jaga-order-confirmation-overview.component';
import { JagaOrderConfirmationItemsComponent } from './components/jaga-order-confirmation-items-component/jaga-order-confirmation-items-component';
import { JagaCartSharedModule } from '../jaga-cart/jaga-cart-shared';
import { JagaOrderConfirmationTotalsComponent } from './components/jaga-order-confirmation-totals/jaga-order-confirmation-totals.component';
import { OrderConfirmationGuard } from '@spartacus/checkout/components';

@NgModule({
  declarations: [
    JagaOrderConfirmationOverviewComponent,
    JagaOrderConfirmationItemsComponent,
    JagaOrderConfirmationTotalsComponent,
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        OrderConfirmationOverviewComponent: {
          component: JagaOrderConfirmationOverviewComponent,
          guards: [OrderConfirmationGuard],
        },
        OrderConfirmationItemsComponent: {
          component: JagaOrderConfirmationItemsComponent,
          guards: [OrderConfirmationGuard],
        },
        OrderConfirmationTotalsComponent: {
          component: JagaOrderConfirmationTotalsComponent,
          guards: [OrderConfirmationGuard],
        },
      },
    } as CmsConfig),
    CardModule,
    JagaCartSharedModule,
    PromotionsModule,
    I18nModule,
  ],
})
export class JagaOrderConfirmationModule {}
