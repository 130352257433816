import { Injectable } from '@angular/core';
import {
  BaseSiteService,
  GlobalMessageService,
  GlobalMessageType,
  WindowRef,
} from '@spartacus/core';
import { JagaUser } from '../../jaga-user/models/jaga-user';
import { map } from 'rxjs/operators';
import { JagaBaseSite } from '../../jaga-model/jaga-misc.model';
import { combineLatest } from 'rxjs';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Injectable({
  providedIn: 'root',
})
export class JagaUserBaseSiteService {
  constructor(
    private baseSiteService: BaseSiteService,
    private windowsRef: WindowRef,
    private userService: UserAccountFacade,
    private globalMessageService: GlobalMessageService
  ) {}

  redirectToUserBaseSite() {
    return combineLatest([
      this.baseSiteService.getActive(),
      this.userService.get(),
    ]).pipe(
      map(([activeStore, user]: [string, JagaUser]) => {
        if (user?.baseSites !== undefined) {
          const jagaBaseSite = user.baseSites.find(
            (baseSite: JagaBaseSite) => baseSite.uid === activeStore
          );
          if (!jagaBaseSite) {
            this.globalMessageService.add(
              {
                key: 'loginForm.redirect.message',
              },
              GlobalMessageType.MSG_TYPE_CONFIRMATION
            );
            setTimeout(() => {
              this.windowsRef.nativeWindow.location.href =
                this.windowsRef.document.location.origin +
                '/' +
                user.baseSites[0].defaultLanguage.isocode;
            }, 3000);
          }
          return user;
        }
      })
    );
  }
}
