import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { ErrorModel, GlobalMessageType, HttpErrorHandler, Priority } from '@spartacus/core';
import { HttpResponseStatus } from '../../model/response-status.model';

const MOLLIE_ERROR_PREFIX = 'Mollie';

@Injectable({
  providedIn: 'root',
})
export class MollieBadRequestHandler extends HttpErrorHandler {
  responseStatus = HttpResponseStatus.BAD_REQUEST;

  handleError(request: HttpRequest<any>, response: HttpErrorResponse) {
    this.handleMollieErrors(request, response);
  }

  getPriority(): Priority {
    return Priority.LOW;
  }

  hasMatch(errorResponse: HttpErrorResponse): boolean {
    const errorModel = this.getErrors(errorResponse)
      .find(error => error.type.startsWith(MOLLIE_ERROR_PREFIX));
    return errorModel ? errorResponse.status === this.responseStatus : false;
  }

  protected getErrors(response: HttpErrorResponse): ErrorModel[] {
    return (response.error?.errors || []);
  }

  private handleMollieErrors(request: HttpRequest<any>, response: HttpErrorResponse) {
    this.getErrors(response)
      .filter(error => error.type.startsWith(MOLLIE_ERROR_PREFIX))
      .forEach(error => {
        this.globalMessageService.add(
          error.message || { key: 'payment.errors.createOrder' },
          GlobalMessageType.MSG_TYPE_ERROR);
      });
  }

}
