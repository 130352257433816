import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CMSEmailSubscribeComponent } from '../../models/email-subscribe-model';

@Component({
  selector: 'jaga-email-subscribe',
  templateUrl: './jaga-email-subscribe.component.html'
})
export class JagaEmailSubscribeComponent {
  constructor(public component: CmsComponentData<CMSEmailSubscribeComponent>) {
  }
}
