<div [ngClass]="compact ? 'cx-compact row' : 'row'">
  <!-- Item Image -->
  <div
    [ngClass]="compact ? 'col-12 col-md-4' : 'col-2'"
    class="cx-image-container"
  >
    <a
      class="jaga-product-image"
      [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl"
      cxModal="dismiss"
      tabindex="-1"
    >
      <cx-media
        [container]="item.product.images?.PRIMARY"
        format="cartIcon"
      ></cx-media>
    </a>
  </div>
  <!-- Item Information -->
  <div [ngClass]="compact ? 'col-12 col-md-8' : 'col-10'" class="cx-info">
    <div class="cx-info-container row">
      <!-- Item Description -->
      <div
        [class.align-self-center]="!compact"
        [ngClass]="compact ? '' : ' col-md-3 col-lg-3 col-xl-5'"
      >
        <div *ngIf="$any(item.product).groupDescription" class="cx-name">
          <a
            [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl"
            class="cx-link"
          >
            {{ $any(item.product).groupDescription }}
          </a>
        </div>

        <div *ngIf="item.product.name" class="name">
          <a
            [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl"
            class="cx-link"
          >
            {{ item.product.name }}
          </a>
        </div>

        <div class="cx-code">
          {{ item.product.code }}
        </div>

        <!-- Variants -->
        <ng-container *ngIf="item.product.baseOptions?.length">
          <div
            *ngFor="
              let variant of item.product.baseOptions[0]?.selected
                ?.variantOptionQualifiers
            "
            class="cx-property"
          >
            <div *ngIf="variant.name && variant.value" class="cx-label">
              {{ variant.name }}: {{ variant.value }}
            </div>
          </div>
        </ng-container>
      </div>
      <!-- Item Price -->
      <div
        *ngIf="item.basePrice"
        [ngClass]="compact ? '' : ' col-md-3 col-lg-3 col-xl-2'"
        class="cx-price"
      >
        <div [ngClass]="compact ? '' : 'd-none'" class="cx-label">
          {{ "cartItems.itemPrice" | cxTranslate }}
        </div>
        <div *ngIf="item.basePrice" class="cx-value">
          {{ item.basePrice?.formattedValue }}
        </div>
      </div>
      <!-- Item Quantity -->

      <div
        [ngClass]="compact ? '' : ' col-8'"
        class="cx-quantity col-8 col-md-3"
      >
        <div
          [ngClass]="compact ? '' : 'd-none'"
          class="cx-label"
          placement="left"
          title="{{ 'cartItems.quantityTitle' | cxTranslate }}"
        >
          {{ "cartItems.quantity" | cxTranslate }}
        </div>
        <div class="cx-value">
          <cx-item-counter
            [allowZero]="true"
            [control]="quantityControl"
            [max]="item.product.stock?.stockLevel || 50"
            [readonly]="!item.updateable || readonly || options.isSaveForLater"
          >
          </cx-item-counter>
        </div>
      </div>
      <!-- Total -->
      <ng-container
        *ngIf="options.isSaveForLater; else total"
        [ngClass]="'d-block d-md-none d-lg-none d-xl-none'"
      >
        <div
          [ngClass]="compact ? '' : ' col-md-3 col-lg-3 col-xl-2'"
          class="cx-total"
        >
          <div
            [ngClass]="compact ? '' : ' d-block d-md-none d-lg-none d-xl-none'"
            class="cx-label"
          >
            {{ "saveForLaterItems.stock" | cxTranslate }}
          </div>
          <div
            *ngIf="item.product?.stock?.stockLevel >= 0; else forceInstock"
            class="cx-value"
          >
            {{ item.product.stock.stockLevel }}
          </div>
          <ng-template #forceInstock>
            <div class="cx-value">
              {{ "saveForLaterItems.forceInStock" | cxTranslate }}
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>
    <!-- Availability -->
    <div
      *ngIf="isProductOutOfStock(item.product)"
      class="cx-availability col-12"
    >
      {{ "productSummary.outOfStock" | cxTranslate }}
    </div>
    <!-- Promotion -->

    <!--    <ng-container-->
    <!--      *ngIf="appliedProductPromotions$ | async as appliedProductPromotions">-->
    <!--      <cx-promotions [promotions]="appliedProductPromotions"></cx-promotions>-->
    <!--    </ng-container>-->

    <!-- Actions -->
    <div
      *ngIf="(!readonly || options.isSaveForLater) && item.updateable"
      class="cx-actions col-12"
    >
      <ng-container *ngIf="!isProductOutOfStock(item.product)">
        <ng-container
          *ngTemplateOutlet="
            options.optionalBtn;
            context: {
              $implicit: { loading: quantityControl.disabled, item: item }
            }
          "
        >
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #total>
  <div
    *ngIf="item.totalPrice"
    [ngClass]="compact ? '' : 'col-md-3 col-xl-2'"
    class="cx-total"
  >
    <div [ngClass]="compact ? '' : ' d-none'" class="cx-label">
      {{ "cartItems.total" | cxTranslate }}
    </div>
    <div class="cx-value">{{ item.totalPrice.formattedValue }}</div>
    <div
      *ngIf="(!readonly || options.isSaveForLater) && item.updateable"
      class="ml-auto mr-3"
    >
      <i (click)="removeItem()" class="fas fa-trash-alt"></i>
    </div>
  </div>
</ng-template>
