import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { JAGA_RESET_PASSWORD_ERRORS } from './jaga-reset-password-state.model';
import { reducer } from './reducers/jaga-reset-password-errors.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(JAGA_RESET_PASSWORD_ERRORS, reducer),
  ],
})
export class JagaResetPasswordStoreModule {}
