import { ValidationErrors } from '@angular/forms';
import {
  CLEAR_RESET_PASSWORD_ERRORS,
  JagaResetPasswordActions,
  UPDATE_RESET_PASSWORD_ERRORS
} from '../actions/jaga-reset-password.actions';

export function reducer(state, action: JagaResetPasswordActions): ValidationErrors {
  switch (action.type) {
    case UPDATE_RESET_PASSWORD_ERRORS:
      return action.payload;
    case CLEAR_RESET_PASSWORD_ERRORS:
      return null;
  }
  return state;
}
