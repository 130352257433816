import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  provideDefaultConfig,
  RoutingConfig,
  UrlModule,
} from '@spartacus/core';
import {
  AddToCartModule,
  defaultViewConfig,
  ListNavigationModule,
  MediaModule,
  PaginationConfig,
  ProductListModule,
  StarRatingModule,
  ViewConfig,
} from '@spartacus/storefront';
import { JagaProductListComponent } from './components/jaga-product-list/jaga-product-list.component';
import { RouterModule } from '@angular/router';
import { JagaProductGridItemComponent } from './components/jaga-product-grid-item/jaga-product-grid-item.component';
import { JagaProductListItemComponent } from './components/jaga-product-list-item/jaga-product-list-item.component';
import { JagaProductBaseItemComponent } from './components/jaga-product-base-item/jaga-product-base-item.component';
import { JagaCartModule } from '../../jaga-cart/jaga-cart.module';
import { ProductVariantsModule } from '@spartacus/product/variants';
import { ProductVariantStyleIconsModule } from '@spartacus/product/variants/root';

@NgModule({
  declarations: [
    JagaProductListComponent,
    JagaProductGridItemComponent,
    JagaProductListItemComponent,
    JagaProductBaseItemComponent,
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CMSProductListComponent: {
          component: JagaProductListComponent,
        },
        ProductGridComponent: {
          component: JagaProductListComponent,
        },
        SearchResultsListComponent: {
          component: JagaProductListComponent,
        },
        SearchResultsGridComponent: {
          component: JagaProductListComponent,
        },
      },
    } as CmsConfig),
    ConfigModule.withConfig({
      pagination: {
        addNext: true,
        addPrevious: true,
        startLabel: '',
        previousLabel: '',
        nextLabel: '',
        endLabel: '',
      },
    } as PaginationConfig),
    ConfigModule.withConfig({
      routing: {
        routes: {
          searchEmpty: { paths: ['searchEmpty'] },
        },
      },
    } as RoutingConfig),
    I18nModule,
    ListNavigationModule,
    ProductListModule,
    UrlModule,
    RouterModule,
    MediaModule,
    StarRatingModule,
    ProductVariantsModule,
    ProductVariantStyleIconsModule,
    AddToCartModule,
    JagaCartModule,
  ],
  providers: [provideDefaultConfig(<ViewConfig>defaultViewConfig)],
})
export class JagaProductListModule {}
