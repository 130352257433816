import { NgModule } from '@angular/core';
import { CHECKOUT_FEATURE, CheckoutRootModule } from '@spartacus/checkout/root';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  imports: [CheckoutRootModule],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [CHECKOUT_FEATURE]: {
          module: () =>
            import('../../../jaga-checkout/jaga-checkout.module').then(
              (m) => m.JagaCheckoutModule
            ),
        },
      },
    }),
  ],
})
export class CheckoutFeatureModule {}
