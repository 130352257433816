<div *ngIf="model$ | async as model" class="cx-page">
  <section *ngIf="model.products?.length" class="cx-page-section">
    <div class="container">
      <div class="row">
        <div *ngIf="viewMode$ | async as viewMode" class="col-12">
          <div class="cx-sorting top">
            <div class="row">
              <div class="col-12 col-lg-4 mr-auto">
                <div class="form-group cx-sort-dropdown">
                  <cx-sorting
                    *ngIf="model.sorts.length > 1"
                    (sortListEvent)="sortList($event)"
                    [selectedOption]="model.pagination.sort"
                    [sortOptions]="model.sorts"
                    placeholder="{{
                      'productList.sortByRelevance' | cxTranslate
                    }}"
                  >
                  </cx-sorting>
                </div>
              </div>
              <div *ngIf="!isInfiniteScroll" class="col-auto">
                <div
                  aria-label="product search pagination"
                  class="cx-pagination"
                >
                  <cx-pagination
                    pageRoute="."
                    [defaultPage]="0"
                    [pagination]="model.pagination"
                    queryParam="currentPage"
                  >
                  </cx-pagination>
                </div>
              </div>
              <div
                class="view-mode-selector-container col-auto ml-auto ml-lg-0"
              >
                <cx-product-view
                  (modeChange)="setViewMode($event)"
                  [mode]="viewMode"
                >
                </cx-product-view>
              </div>
            </div>
          </div>
          <div class="cx-product-container">
            <!-- Product list when using pagination -->
            <ng-container *ngIf="!isInfiniteScroll; else infiniteScroll">
              <ng-container *ngIf="viewMode === ViewModes.Grid">
                <div class="row">
                  <jaga-product-grid-item
                    *ngFor="let product of model?.products"
                    [product]="product"
                    class="col-12 col-sm-6 col-lg-4"
                  >
                  </jaga-product-grid-item>
                </div>
              </ng-container>

              <ng-container *ngIf="viewMode === ViewModes.List">
                <jaga-product-list-item
                  *ngFor="let product of model?.products"
                  [product]="product"
                  class="cx-product-search-list"
                >
                </jaga-product-list-item>
              </ng-container>
            </ng-container>

            <!-- Product list when using infinite scroll -->
            <ng-template #infiniteScroll>
              <cx-product-scroll
                [inputViewMode]="viewMode"
                [model]="model"
                [scrollConfig]="scrollConfig"
              >
              </cx-product-scroll>
            </ng-template>
          </div>
          <div class="cx-sorting bottom mt-4">
            <div class="row">
              <div class="col-12 col-lg-4 mr-auto">
                <div class="form-group cx-sort-dropdown">
                  <cx-sorting
                    *ngIf="model.sorts.length > 1"
                    (sortListEvent)="sortList($event)"
                    [selectedOption]="model.pagination.sort"
                    [sortOptions]="model.sorts"
                    placeholder="{{
                      'productList.sortByRelevance' | cxTranslate
                    }}"
                  >
                  </cx-sorting>
                </div>
              </div>
              <div
                *ngIf="!isInfiniteScroll"
                aria-label="product search pagination"
                class="col-auto"
              >
                <div class="cx-pagination">
                  <cx-pagination
                    pageRoute="."
                    [defaultPage]="0"
                    [pagination]="model.pagination"
                    queryParam="currentPage"
                  >
                  </cx-pagination>
                </div>
              </div>
              <div
                class="view-mode-selector-container col-auto ml-auto ml-lg-0"
              >
                <cx-product-view
                  (modeChange)="setViewMode($event)"
                  [mode]="viewMode"
                >
                </cx-product-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
