import { Component } from '@angular/core';
import {
  CartItemComponent,
  CartItemContext,
  CartItemContextSource,
} from '@spartacus/storefront';

@Component({
  selector: 'jaga-cart-item',
  templateUrl: './jaga-cart-item.component.html',
  providers: [
    CartItemContextSource,
    { provide: CartItemContext, useExisting: CartItemContextSource },
  ],
})
export class JagaCartItemComponent extends CartItemComponent {
  constructor(protected cartItemContextSource: CartItemContextSource) {
    super(cartItemContextSource);
  }
}
