import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { JagaProduct } from '../../models/product.model';
import { JagaCurrentProductService } from '../../jaga-current-product.service';
import { Config, OccConfig } from '@spartacus/core';

@Component({
  selector: 'jaga-product-manuals',
  templateUrl: './jaga-product-manuals.component.html',
})
export class JagaProductManualsComponent implements OnInit {
  product$: Observable<JagaProduct> = this.currentProductService.getProduct();

  constructor(
    @Inject(Config) protected config: Config,
    private currentProductService: JagaCurrentProductService
  ) {}

  ngOnInit(): void {}

  get getBaseUrl(): string {
    return (
      (this.config as OccConfig).backend.media.baseUrl ||
      (this.config as OccConfig).backend.occ.baseUrl ||
      ''
    );
  }
}
