<ng-container *ngIf="user$ | async as user; else login">
  <cx-page-slot position="HeaderLinks"></cx-page-slot>
</ng-container>

<ng-template #login>
  <a role="link" [routerLink]="{ cxRoute: 'login' } | cxUrl">
    <cx-icon class="fa fa-user"></cx-icon>

    <span class="login">
      {{'miniLogin.signInRegister' | cxTranslate}}
    </span>
  </a>
</ng-template>
