import { Component } from '@angular/core';
import { CartItemListComponent } from '@spartacus/storefront';
import {
  ActiveCartService,
  MultiCartService,
  SelectiveCartService,
  UserIdService,
} from '@spartacus/core';

@Component({
  selector: 'jaga-cart-item-list',
  templateUrl: './jaga-cart-item-list.component.html',
})
export class JagaCartItemListComponent extends CartItemListComponent {
  constructor(
    protected activeCartService: ActiveCartService,
    protected selectiveCartService: SelectiveCartService,
    protected userIdService: UserIdService,
    protected multiCartService: MultiCartService
  ) {
    super(
      activeCartService,
      selectiveCartService,
      userIdService,
      multiCartService
    );
  }
}
