import { User } from '@spartacus/core';
import { Observable } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  inject,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  HamburgerMenuService,
  NavigationUIComponent,
} from '@spartacus/storefront';
import { UserProfileFacade } from '@spartacus/user/profile/root';

@Component({
  selector: 'jaga-acc-nav-ui',
  templateUrl: 'jaga-acc-nav-ui.component.html',
  styleUrls: ['./jaga-acc-nav-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JagaAccNavUIComponent extends NavigationUIComponent {
  private _renderer = inject(Renderer2);
  private _elemRef = inject(ElementRef);
  private _userProfileFacade = inject(UserProfileFacade);

  public user$: Observable<User> = this._userProfileFacade.get();

  @ViewChild('navigation') nav: ElementRef;

  @HostListener('document:click')
  clickedOutside() {
    if (this._elemRef.nativeElement.classList.contains('is-open')) {
      this._renderer.removeClass(this._elemRef.nativeElement, 'is-open');
      this._renderer.removeClass(this.nav.nativeElement, 'is-open');
    }
  }
}
