import { ChangeDetectorRef, Component, Input } from '@angular/core';
import {
  AddToCartComponent,
  CurrentProductService,
  ModalService,
} from '@spartacus/storefront';
import { ActiveCartService } from '@spartacus/core';
import { JagaAddedToCartDialogComponent } from './jaga-added-to-cart-dialog/jaga-added-to-cart-dialog.component';
import { FormControl, FormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';

@Component({
  selector: 'jaga-add-to-cart',
  templateUrl: './jaga-add-to-cart.component.html',
})
export class JagaAddToCartComponent extends AddToCartComponent {
  Math = Math;
  increment: boolean;

  @Input() confirmation: 'MODAL' | 'NONE' = 'MODAL';

  addToCartForm = new FormGroup({
    quantity: new FormControl(1),
  });

  constructor(
    modalService: ModalService,
    currentProductService: CurrentProductService,
    cd: ChangeDetectorRef,
    activeCartService: ActiveCartService
  ) {
    super(modalService, currentProductService, cd, activeCartService);
  }

  addToCart() {
    const quantity = this.addToCartForm.get('quantity').value;
    if (!this.productCode || quantity <= 0) {
      return;
    }
    // check item is already present in the cart
    // so modal will have proper header text displayed
    this.activeCartService
      .getEntry(this.productCode)
      .pipe(take(1))
      .subscribe((entry) => {
        if (entry) {
          this.increment = true;
        }
        this.activeCartService.addEntry(this.productCode, quantity);
        if (this.confirmation === 'MODAL') {
          this.openJagaAddedToCartModal();
        }
        this.increment = false;
      });
  }

  private openJagaAddedToCartModal() {
    let modalInstance: any;
    this.modalRef = this.modalService.open(JagaAddedToCartDialogComponent, {
      centered: true,
      size: 'lg',
    });

    modalInstance = this.modalRef.componentInstance;
    modalInstance.entry$ = this.activeCartService.getLastEntry(
      this.productCode
    );
    modalInstance.cart$ = this.activeCartService.getActive();
    modalInstance.loaded$ = this.activeCartService.isStable();
    modalInstance.quantity = this.quantity;
    modalInstance.increment = this.increment;
  }
}
