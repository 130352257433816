import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { BadRequestHandler, GlobalMessageService, InterceptorUtil } from '@spartacus/core';
import { IGNORE_VALIDATION_ERRORS } from '../../constants';

@Injectable({
  providedIn: 'root',
})
export class JagaBadRequestHandler extends BadRequestHandler {

  constructor(globalMessageService: GlobalMessageService) {
    super(globalMessageService);
  }

  handleValidationError(request: HttpRequest<any>, response: HttpErrorResponse) {
    if (!InterceptorUtil.getInterceptorParam(IGNORE_VALIDATION_ERRORS, request.headers)) {
      super.handleValidationError(request, response);
    }
  }
}
