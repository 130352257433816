import { OccConfig } from '@spartacus/core';

export const jagaOccProductConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        product: {
          default:
            'products/${productCode}?fields=DEFAULT,groupDescription,coolingAssignments,heatingAssignments,averageRating,images(FULL),classifications,manufacturer,numberOfReviews,categories(FULL),baseOptions,baseProduct,variantOptions,variantType,maxDeliveryDays',
          list:
            'products/${productCode}?fields=code,name,groupDescription,coolingAssignments,heatingAssignments,stock(DEFAULT),summary,price(formattedValue),images(DEFAULT,galleryIndex)',
          details:
            'products/${productCode}?fields=averageRating,stock(DEFAULT),description,availableForPickup,code,url,price(DEFAULT),numberOfReviews,manufacturer,categories(FULL),priceRange,multidimensional,configuratorType,configurable,tags,images(FULL),colorDescription,deliveryDescription,datasheets(FULL),manuals(FULL),dimensionImage(DEFAULT),connectionDescription,logos(DEFAULT),maxDeliveryDays',
          attributes: 'products/${productCode}?fields=classifications',
          variants:
            'products/${productCode}?fields=name,categories,multidimensional,variantMatrix,groupDescription,purchasable,baseOptions(DEFAULT),baseProduct,variantOptions(DEFAULT),variantType',
        },
        productSearch:
          'products/search?fields=products(code,name,groupDescription,coolingAssignments,heatingAssignments,summary,price(FULL),images(DEFAULT),stock(FULL),averageRating,variantOptions),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery',
      },
    },
  },
};
