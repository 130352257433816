import { Injectable } from '@angular/core';
import { CurrentProductService } from '@spartacus/storefront';
import { ProductScope, ProductService, RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { JagaProduct } from './models/product.model';

@Injectable({
  providedIn: 'root'
})
export class JagaCurrentProductService extends CurrentProductService {

  constructor(routingService: RoutingService, productService: ProductService) {
    super(routingService, productService);
  }

  getProduct(scopes?: (ProductScope | string)[] | ProductScope | string): Observable<JagaProduct | null> {
    return super.getProduct(scopes);
  }
}
