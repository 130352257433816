<form (submit)="addToCart()" *ngIf="productCode" [formGroup]="addToCartForm">
  <div *ngIf="showQuantity" class="quantity">
    <label>{{ 'addToCart.quantity' | cxTranslate }}</label>
    <cx-item-counter
      *ngIf="hasStock"
      [control]="addToCartForm.get('quantity')"
      [max]="maxQuantity ? Math.min(50, maxQuantity) : 50">
    </cx-item-counter>
    <span class="info">
      {{ hasStock ? '' : ('addToCart.outOfStock' | cxTranslate) }}
    </span>
  </div>

  <button
    *ngIf="hasStock"
    [disabled]="quantity <= 0 || quantity > maxQuantity"
    class="btn btn-primary btn-block"
    type="submit">
    {{ 'addToCart.addToCart' | cxTranslate }}
  </button>
</form>
