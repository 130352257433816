<ng-container *ngIf="product$ | async as product">

  <a *ngFor="let manual of product.manuals"
     href="{{manual.url.startsWith('http')? manual.url: getBaseUrl + manual.url}}"
     target="_blank">
    <cx-media
      [alt]="manual.image?.altText"
      [container]="manual.image"
      [format]="manual.image?.format"></cx-media>
    {{ manual.name }}
  </a>

</ng-container>
