import { Component, Input, OnInit } from '@angular/core';
import {
  Product,
  ProductReference,
  ProductReferenceService,
} from '@spartacus/core';
import { filter, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'jaga-added-to-cart-dialog-carousel',
  templateUrl: './jaga-added-to-cart-dialog-carousel.component.html',
})
export class JagaAddedToCartDialogCarouselComponent implements OnInit {
  @Input() product: Product;

  items$: Observable<Observable<Product>[]>;

  constructor(protected referenceService: ProductReferenceService) {}

  ngOnInit(): void {
    this.items$ = this.getProductReferences(this.product?.code, 'MANDATORY');
  }

  private getProductReferences(
    code: string,
    referenceType: string
  ): Observable<Observable<Product>[]> {
    return this.referenceService.getProductReferences(code, referenceType).pipe(
      filter(Boolean),
      map((refs: ProductReference[]) => {
        return refs.map((ref) => of(ref.target));
      })
    );
  }
}
