import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  RoutingService,
  UserService,
  GlobalMessageService,
  GlobalMessageType,
  TranslationService,
} from '@spartacus/core';
import { combineLatest, Subscription } from 'rxjs';
import { JagaResetPasswordState } from '../../../jaga-user/store/jaga-reset-password-state.model';
import { Store } from '@ngrx/store';
import {
  ClearResetPasswordErrorsAction,
  UpdateResetPasswordErrorsAction,
} from '../../../jaga-user/store/actions/jaga-reset-password.actions';
import { JagaCustomFormValidators } from '../../../jaga-util/validators/jaga-custom-form-validators';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { catchError, map, take } from 'rxjs/operators';

@Component({
  selector: 'jaga-reset-password',
  templateUrl: './jaga-reset-password.component.html',
})
export class JagaResetPasswordComponent implements OnInit, OnDestroy {
  token: string;
  email: string;
  subscription = new Subscription();
  readonly passwordFormName: string = 'password';
  readonly rePasswordFormName: string = 'repassword';
  resetPasswordForm: FormGroup = this.fb.group(
    {
      [this.passwordFormName]: [
        '',
        [
          Validators.required,
          JagaCustomFormValidators.passwordRequirementsValidator,
        ],
      ],
      [this.rePasswordFormName]: ['', []],
    },
    {
      validators: JagaCustomFormValidators.passwordsMustMatchValidator(
        this.passwordFormName,
        this.rePasswordFormName
      ),
    }
  );

  constructor(
    private fb: FormBuilder,
    private routingService: RoutingService,
    private store: Store<JagaResetPasswordState>,
    protected messageService: GlobalMessageService,
    protected userPasswordFacade: UserPasswordFacade,
    private translationService: TranslationService
  ) {}

  get passwordFormControl(): FormControl {
    return this.resetPasswordForm.get(this.passwordFormName) as FormControl;
  }

  get rePasswordFormControl(): FormControl {
    return this.resetPasswordForm.get(this.rePasswordFormName) as FormControl;
  }

  ngOnInit() {
    this.subscription.add(
      this.routingService.getRouterState().subscribe((state) => {
        this.token = state.state.queryParams.token;
        this.email = state.state.queryParams.email;
      })
    );

    this.subscription.add(
      this.resetPasswordForm.valueChanges.subscribe((_) => {
        const errors = {
          ...this.passwordFormControl.errors,
          ...this.rePasswordFormControl.errors,
        };

        this.store.dispatch(new UpdateResetPasswordErrorsAction(errors));
      })
    );
  }

  resetPassword() {
    if (this.resetPasswordForm.valid) {
      const password = this.resetPasswordForm.get('password').value;
      combineLatest([
        this.userPasswordFacade.reset(this.token, password),
        this.translationService.translate(
          'forgottenPassword.passwordResetSuccess'
        ),
      ])
        .pipe(
          take(1),
          map(([_, successMsg]) => {
            this.messageService.add(
              successMsg,
              GlobalMessageType.MSG_TYPE_CONFIRMATION
            );
            this.routingService.go({ cxRoute: 'login' });
          }),
          catchError((err) => {
            this.messageService.add(
              err.details[0].message,
              GlobalMessageType.MSG_TYPE_ERROR
            );
            throw err;
          })
        )
        .subscribe();
    } else {
      this.resetPasswordForm.markAllAsTouched();
    }
  }

  ngOnDestroy() {
    this.store.dispatch(new ClearResetPasswordErrorsAction());
    this.subscription?.unsubscribe();
  }
}
