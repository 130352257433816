import { Component } from '@angular/core';
import { CmsComponentData, ProductCarouselComponent } from '@spartacus/storefront';
import { AuthService, CmsProductCarouselComponent, ProductService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'jaga-product-carousel',
  templateUrl: './jaga-product-carousel.component.html'
})
export class JagaProductCarouselComponent extends ProductCarouselComponent {

  constructor(componentData: CmsComponentData<CmsProductCarouselComponent>,
              productService: ProductService,
              private authService: AuthService) {
    super(componentData, productService);
  }

  get isAuthenticated(): Observable<boolean> {
    return this.authService.isUserLoggedIn();
  }

}
