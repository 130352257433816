<ng-container *ngIf="cart$ | async as cart">
  <div class="form-group">
    <form (ngSubmit)="applyVoucher()" [formGroup]="couponForm">
      <label class="jaga-cart-coupon-container d-flex">
        <input
          class="form-control input-coupon-code"
          formControlName="couponCode"
          placeholder="{{ 'voucher.placeholder' | cxTranslate }}"
          type="text"/>
        <button
          [class.disabled]="cartIsLoading$ | async"
          [disabled]="cartIsLoading$ | async"
          class="btn btn-primary apply-coupon-button ml-3"
          type="submit">
          {{ 'voucher.apply' | cxTranslate }}
        </button>
      </label>
    </form>
  </div>

  <cx-applied-coupons
    [cartIsLoading]="cartIsLoading$ | async"
    [isReadOnly]="false"
    [vouchers]="cart.appliedVouchers">
  </cx-applied-coupons>

  <ng-container *ngIf="applicableCoupons && applicableCoupons.length > 0">
    <div class="cx-available-coupon">
      <div class="title cx-cart-coupon-title">
        {{ 'voucher.availableCoupons' | cxTranslate }}
      </div>
      <div class="message">
        {{ 'voucher.availableCouponsLabel' | cxTranslate }}
      </div>
      <div class="scroll">
        <div *ngFor="let coupon of applicableCoupons" class="coupons card">
          <button
            (click)="applyCustomerCoupon(coupon.couponId)"
            [class.disabled]="cartIsLoading$ | async"
            [disabled]="cartIsLoading$ | async"
            class="coupon-id link"
          >
            {{ coupon.couponId }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
