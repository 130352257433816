import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  RoutingService,
  StateWithProcess,
  UserIdService,
} from '@spartacus/core';
import { OrderService, StateWithOrder } from '@spartacus/order/core';
import { MollieSelectors } from '../store/selectors';

@Injectable({
  providedIn: 'root',
})
export class MollieUserOrderService extends OrderService {
  constructor(
    protected orderStateStore: Store<StateWithOrder>,
    protected processStateStore: Store<StateWithProcess<void>>,
    protected userIdService: UserIdService,
    protected routingService: RoutingService
  ) {
    super(orderStateStore, processStateStore, userIdService, routingService);
  }

  getOrderDetailsErrors(): Observable<boolean> {
    return this.orderStateStore.pipe(
      select(MollieSelectors.getOrderDetailsError)
    );
  }
}
