import { Pipe, PipeTransform } from '@angular/core';
import { Renderer2 } from '@angular/core';

import { Router} from '@angular/router';

@Pipe({
  name: 'jagaFixAnchor'
})
export class JagaFixAnchorPipe implements PipeTransform {

  constructor(private renderer: Renderer2,
              private router: Router) {
  }

  transform(value: string): string {
    let fullPath: string = this.router.url;
    const indexLastHashtag: number = fullPath.lastIndexOf('#');

    if (indexLastHashtag > -1) {
      fullPath = fullPath.substr(0, indexLastHashtag);
    }

    const template = this.renderer.createElement('template');
    template.innerHTML = value;

    const anchorNodes: NodeList = template.content.querySelectorAll('a');
    const anchors: Node[] = Array.from(anchorNodes);

    for (const anchor of anchors) {
      const href: string = (anchor as HTMLAnchorElement).getAttribute('href');
      if (href?.indexOf('#') === 0) {
        this.renderer.setProperty(
          anchor,
          'href',
          `/${fullPath}${href}`
        );
      }
    }
    return template.innerHTML;
  }
}
