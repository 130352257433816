export interface PaymentType {
  code?: string;
  displayName?: string;
}

export enum PaymentTypes {
  ACCOUNT_PAYMENT = 'ACCOUNT',
  CARD_PAYMENT = 'CARD',
  PRE_PAYMENT = 'PREPAYMENT',
}
