<a [attr.aria-label]="'miniCart.item' | cxTranslate: { count: quantity$ | async }"
   [routerLink]="{ cxRoute: 'cart' } | cxUrl"
   class="jaga-cart">

  <span class="jaga-cart-icon-wrapper">
    <cx-icon [type]="iconTypes.CART"></cx-icon>

    <span *ngIf="quantity$ | async as quantity" class="count">
      {{ 'miniCart.count' | cxTranslate: {count: quantity} }}
    </span>
  </span>

  <ng-container *ngIf="(quantity$ | async) > 0 && total$ | async as total; else cart">
    <span class="total">
      {{ 'miniCart.total' | cxTranslate: {total: total} }}
    </span>
  </ng-container>

  <ng-template #cart>
    <span class="cart">
    {{ 'miniCart.jaga-cart' | cxTranslate }}
    </span>
  </ng-template>

</a>
