import { ActionReducerMap } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { QuickOrderState } from '../quick-order-state';
import * as fromQuickOrderEntries from './quick-order-entries.reducer';
import * as fromQuickOrderSearch from './quick-order-search.reducer';

export function getReducers(): ActionReducerMap<QuickOrderState> {
  return {
    entries: fromQuickOrderEntries.reducer,
    search: fromQuickOrderSearch.reducer,
  };
}

export const reducerToken: InjectionToken<ActionReducerMap<QuickOrderState>> =
  new InjectionToken<ActionReducerMap<QuickOrderState>>(
    'JagaQuickOrderReducers'
  );

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers,
};
