import { JagaUserService } from '../../jaga-user/services/jaga-user.service';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

export class JagaCustomFormValidators {
  static regExForNoSpecialCharsAndNumber =
    /[\d`§!@#$%^&*()_+=\[\]{};:"\\|,.<>\/?~±]/;

  static handleVatIdError(error: any): Observable<ValidationErrors> {
    const errorKey = 'vatID.' + error.error.errors[0].reason;
    return of({ [errorKey]: true });
  }

  static handleVatIdCustomerNumberError(): Observable<ValidationErrors> {
    const errorKey = 'customerNumber.serverError';
    return of({ [errorKey]: true });
  }

  static handlePostCodeError(): Observable<ValidationErrors> {
    const errorKey = 'pattern';
    return of({ [errorKey]: true });
  }

  static handleEmailError(): Observable<ValidationErrors> {
    const errorKey = 'email.alreadyExists';
    return of({ [errorKey]: true });
  }

  static existingCustomerVatIdValidator(
    jagaUserService: JagaUserService
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return jagaUserService.validateVATNumber(control.value).pipe(
        map((result: boolean) => (result ? null : { 'vatID.unknown': true })),
        catchError(this.handleVatIdError)
      );
    };
  }

  static newCustomerVatIdValidator(
    jagaUserService: JagaUserService
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return jagaUserService.validateVATNumber(control.value).pipe(
        map((result: boolean) =>
          result ? { 'vatID.alreadyExists': true } : null
        ),
        catchError(this.handleVatIdError)
      );
    };
  }

  static newCustomerEmailValidator(
    jagaUserService: JagaUserService
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return jagaUserService.checkIfEmailExists(control.value).pipe(
        map((result: boolean) =>
          result ? { 'email.alreadyExists': true } : null
        ),
        catchError(this.handleEmailError)
      );
    };
  }

  static newPostalCodeValidator(
    jagaUserService: JagaUserService
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return jagaUserService.validatePostalCode(control.value).pipe(
        map((result: boolean) => (!result ? { pattern: true } : null)),
        catchError(this.handlePostCodeError)
      );
    };
  }

  static passwordRequirementsValidator(
    control: AbstractControl
  ): ValidationErrors {
    const result = {
      number: /(?=(?:.*?[0-9]){1}).*/.test(control.value),
      uppercase: /(?=(?:.*?[A-Z]){1}).*/.test(control.value),
      minLength: /^.{8,}$/.test(control.value),
    };

    Object.keys(result).forEach((key) => {
      if (result[key]) {
        delete result[key];
      }
    });

    return result;
  }

  static passwordsMustMatchValidator(
    password: string,
    passwordConfirmation: string
  ): any {
    return (formGroup: FormGroup) =>
      this.controlsMustMatch(
        formGroup,
        password,
        passwordConfirmation,
        'mustMatch'
      );
  }

  static vatIdMustMatchCustomerNumberValidator(
    jagaUserService: JagaUserService,
    vatIdControlName: string,
    customerNumberControlName: string
  ): any {
    return (formGroup: FormGroup) =>
      this.existingCustomerVatIdCustomerNumberValidator(
        formGroup,
        jagaUserService,
        vatIdControlName,
        customerNumberControlName
      );
  }

  static validateString(control: AbstractControl): ValidationErrors | null {
    return JagaCustomFormValidators.containsSpecialCharsAndNumberString(
      control.value
    )
      ? { notAllowedContent: true }
      : null;
  }

  static validatePhoneNumber(
    control: AbstractControl
  ): ValidationErrors | null {
    const regExForOnlyNumbersWithoutSpaces = /^$|^[-\d]([0-9/]+\s?)*$/; // should start with number or empty and accept only digits or / or space
    return !regExForOnlyNumbersWithoutSpaces.test(control.value)
      ? { phoneNumberFormatIncorrect: true }
      : null;
  }

  private static existingCustomerVatIdCustomerNumberValidator(
    formGroup: FormGroup,
    jagaUserService: JagaUserService,
    vatIdControlName: string,
    customerNumberControlName: string
  ): Observable<ValidationErrors | null> {
    const vatIdControl = formGroup.controls[vatIdControlName];
    const customerNumberControl = formGroup.controls[customerNumberControlName];
    if (
      !(vatIdControl && customerNumberControl) ||
      vatIdControl.errors ||
      customerNumberControl.errors
    ) {
      return of(null);
    }

    return jagaUserService
      .validateVATNumberCustomerNumber(
        vatIdControl.value,
        customerNumberControl.value
      )
      .pipe(
        map((result: boolean) =>
          result ? null : { 'customerNumber.mismatch': true }
        ),
        catchError(this.handleVatIdCustomerNumberError),
        tap((errors: ValidationErrors | null) => {
          customerNumberControl.setErrors(errors);
        })
      );
  }

  private static controlsMustMatch(
    formGroup: FormGroup,
    firstControlName: string,
    secondControlName: string,
    errorName: string
  ): void {
    const firstControl = formGroup.controls[firstControlName];
    const secondControl = formGroup.controls[secondControlName];

    if (secondControl.errors && !secondControl.errors[errorName]) {
      return;
    }

    const hasError =
      !(firstControl.value && secondControl.value) ||
      firstControl.value !== secondControl.value;

    secondControl.setErrors(hasError ? { [errorName]: true } : null);
  }

  private static containsSpecialCharsAndNumberString(value: string): Boolean {
    return this.regExForNoSpecialCharsAndNumber.test(value);
  }
}
